
export const mxInteractiveDemoApp = Vue.createApp({
  data() {
    return {
      menuLeftContent: window.mxDemoJson.menuLeftJson,
      bodySectionsContent: window.mxDemoJson.bodySectionsJson,
      addlResContent: window.mxDemoJson.addlResJson,
      breadcrumbActive: false,
      activeTitle: "home",
      activeSection: 0,
      currentSection: 0,
      activeSubSection: 0,
      activePage: 0,
      nextSection: false,
      nextSectionTitle: "no section title found",
      nextSubSectionTitle: "no sub section title found",
      demoComplete: false,
      hideBody: false,
      menuRightActive: false,
      formComplete: false,
      subSectionProgression: {
        build: [
          {
            complete: false,
            totalPages: 1,
            currentPage: 0,
            strokeDashArray: "0, 50"
          },
          {
            complete: false,
            totalPages: 6,
            currentPage: 0,
            strokeDashArray: "0, 50"
          },
          {
            complete: false,
            totalPages: 3,
            currentPage: 0,
            strokeDashArray: "0, 50"
          },
          {
            complete: false,
            totalPages: 3,
            currentPage: 0,
            strokeDashArray: "0, 50"
          }
        ],
        execute: [
          {
            complete: false,
            totalPages: 1,
            currentPage: 0,
            strokeDashArray: "0, 50"
          },
          {
            complete: false,
            totalPages: 4,
            currentPage: 0,
            strokeDashArray: "0, 50"
          },
          {
            complete: false,
            totalPages: 4,
            currentPage: 0,
            strokeDashArray: "0, 50"
          },
          {
            complete: false,
            totalPages: 4,
            currentPage: 0,
            strokeDashArray: "0, 50"
          },
          {
            complete: false,
            totalPages: 3,
            currentPage: 0,
            strokeDashArray: "0, 50"
          }
        ],
        review: [
          {
            complete: false,
            totalPages: 1,
            currentPage: 0,
            strokeDashArray: "0, 50"
          },
          {
            complete: false,
            totalPages: 4,
            currentPage: 0,
            strokeDashArray: "0, 50"
          },
        ],
        analyze: [
          {
            complete: false,
            totalPages: 1,
            currentPage: 0,
            strokeDashArray: "0, 50"
          },
          {
            complete: false,
            totalPages: 3,
            currentPage: 0,
            strokeDashArray: "0, 50"
          },
        ]
      }
    };
  },
  methods: {
    // Activate Section Directly DONE
    activateSection(sectionIndex) {
      this.nextSection = false;
      // this.clearDemoComplete();
      if(sectionIndex === 0) {
        this.activeSection = 0;
        this.currentSection = 0;
        this.activeTitle = "home";
      } else {
        this.activeSection = sectionIndex;
      }
    },
    // Activate SubSection Directly
    activateSubmenu(title, sectionIndex, subIndex) {
      console.log("activateSubmenu: ", title, sectionIndex, subIndex);
      if(this.formComplete || subIndex === 0) {
        this.clearDemoComplete();
        this.currentSection = sectionIndex;
        this.activeSubSection = subIndex;
        if(subIndex === 0) {
          this.subSectionProgression[title][subIndex].complete = true;
          this.activatePage(1);
        } else {
          // console.warn("activateSubmenu 'else' triggered");
          const currPage = this.subSectionProgression[title][subIndex].currentPage;
          if(currPage === 0) {
            this.activatePage(1);
          } else {
            this.activatePage(this.subSectionProgression[title][subIndex].currentPage);
          }
        }
      } else {
        $('.btn.js-launchMxDemoForm').click();
        return;
      }

    },
    activatePage(pageNum) {
      this.activePage = pageNum;
      this.updateCurrentPage();
    },
    getNextSubSectionTitle() {
      const currSubSection = this.activeSubSection;
      const currSection = this.currentSection;
      let nextSubSectionTitle = this.nextSubSectionTitle;
      if(this.bodySectionsContent[currSection].subSections[currSubSection]?.subSectionTitle !== undefined) {
        nextSubSectionTitle = this.bodySectionsContent[currSection].subSections[currSubSection].subSectionTitle;
      }
      this.nextSubSectionTitle = nextSubSectionTitle;
    },
    getNextSectionTitle() {
      const currSection = this.currentSection;
      let nextTitle = this.nextSectionTitle;
      // console.log("nextTitle: ", nextTitle);
      if(this.bodySectionsContent[currSection + 1]?.sectionTitle !== undefined) {
        nextTitle = this.bodySectionsContent[currSection + 1].sectionTitle;
      }
      this.nextSectionTitle = nextTitle;
    },
    nextPage() {
      const nextPageExists = this.validatePageProgress();
      const subSectionsComplete = this.validateSectionProgress();
      // console.warn("nextPageExists: ", nextPageExists, "subSectionsComplete: ", subSectionsComplete);

      if(nextPageExists) {
        this.activePage += 1;
      }
      else if (!nextPageExists && !subSectionsComplete) {
        this.activeSubSection += 1;
        const currPage = this.subSectionProgression[this.activeTitle][this.activeSubSection].currentPage;
        if(currPage === 0) {
          this.activatePage(1);
        } else {
          this.activatePage(currPage);
        }
      } else {
        console.warn("Next Page Error reached!!!!");
      }
    },
    nextSectionActivate() {
      this.activePage = 0;
      this.activeSubSection = 0;
      this.activeSection += 1;
      this.currentSection = this.activeSection;
      this.activateSubmenu(this.nextSectionTitle, this.activeSection, this.activeSubSection);
    },
    previousPage() {
      this.activePage -= 1;
    },
    validatePageProgress() {
      const totPages = this.subSectionProgression[this.activeTitle][this.activeSubSection].totalPages;
      // console.warn("currPage: ", currPage, "totPages: ", totPages);
      const nextIsValid = (this.activePage === totPages);
      return nextIsValid;
    },
    validateSectionProgress() {
      const subSectionsLength = this.subSectionProgression[this.activeTitle].length;
      const subSectionsDone = this.activeSubSection;
      const sectionComplete = ((subSectionsLength - 1) === subSectionsDone);
      // console.warn("subSectionsLength: ", subSectionsLength, "subSectionsDone: ", subSectionsDone, "sectionComplete: ", sectionComplete);
      return sectionComplete;
    },
    openAdditionalResources() {
      // console.log("Open Additional Resources");
      this.hideBody = true;
    },
    updateCurrentPage() {
      this.subSectionProgression[this.activeTitle][this.activeSubSection].currentPage = this.activePage;
    },
    getCirclePercentage() {
      // console.log("getCirclePercentage: ", title, subIndex);
      const totPages = this.subSectionProgression[this.activeTitle][this.activeSubSection].totalPages;
      const currPage = this.activePage;
      if(currPage === 0) {
        return "0, 50";
      }
      const strokeTotal = 50;
      const totalPercent = (currPage/totPages);
      const piePercent = (totalPercent * strokeTotal);
      const result = piePercent +', '+ strokeTotal;
      // return result;
      this.subSectionProgression[this.activeTitle][this.activeSubSection].strokeDashArray = result;
    },
    checkIfSubSectionComplete() {
      const totPages = this.subSectionProgression[this.activeTitle][this.activeSubSection].totalPages;
      const currPage = this.activePage;
      if(totPages <= currPage) {
        this.subSectionProgression[this.activeTitle][this.activeSubSection].complete = true;
      } else {
        this.subSectionProgression[this.activeTitle][this.activeSubSection].complete = false;
      }
    },
    checkIfSectionComplete() {
      // console.warn("Check if section completed");
      const nextPageExists = this.validatePageProgress();

      if(this.activeSubSection === 0) { return; }
      const subSectionsLength = this.subSectionProgression[this.activeTitle].length;
      const subSectionsDone = this.activeSubSection;
      const sectionComplete = ((subSectionsLength - 1) === subSectionsDone);
      if(sectionComplete && !nextPageExists) {
        this.nextSection = true;
      } else {
        this.nextSection = false;
      }
    },
    checkIfDemoComplete() {
      if(this.activePage === 3 && this.activeSubSection === 1 && this.activeSection === 4) {
        this.demoComplete = true;
      }
    },
    clearDemoComplete() {
      this.demoComplete = false;
      this.hideBody = false;
    },
    pageAndSubSectionFunctions() {
      this.updateCurrentPage();
      this.getCirclePercentage();
      this.checkIfSubSectionComplete();
      this.checkIfSectionComplete();
      this.checkIfDemoComplete();
      this.getNextSectionTitle();
      this.getNextSubSectionTitle();
    },
    toggleMenuRight() {
      this.menuRightActive = !this.menuRightActive;
    },
    clearSubSectionProgression() {
      for (const section in this.subSectionProgression) {
        for (const subSection in this.subSectionProgression[section]) {
          // console.warn(this.subSectionProgression[section][subSection].complete);
          this.subSectionProgression[section][subSection].complete = false;
          this.subSectionProgression[section][subSection].currentPage = 0;
          this.subSectionProgression[section][subSection].strokeDashArray = "0, 50";
        }
      }
    },
    restartDemo() {
      this.clearDemoComplete();
      this.activeSection = 0;
      this.currentSection = 0;
      this.clearSubSectionProgression();
      this.menuRightActive = false;
    }
  },
  watch: {
    activePage() {
      this.pageAndSubSectionFunctions();
    },
    activeSubSection() {
      this.pageAndSubSectionFunctions();
    },
    activeSection() {
      this.activeTitle = this.bodySectionsContent[this.activeSection].sectionTitle;
      this.breadcrumbActive = this.activeSection > 0;
    }
  }
});

mxInteractiveDemoApp.mount('#mx-interactive-demo-parent');

// Outside of Vue JS
window.mxInteractiveDemoApp = mxInteractiveDemoApp;
