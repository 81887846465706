<script lang="ts">
  // Turn off inheritance through attributes
  export default {
    inheritAttrs: false
  };
</script>

<script setup lang="ts">
  import { type FormWidgetData } from "../../../widgetDataTs.js";
  import { watch, computed } from 'vue';
  import customResourceForm from "../form-element/resource-form-custom-resource-base.vue";
  import { useFormServices } from "../../composables/use-form-services.js";
  import { isNil } from "@mcwd/typescript-type-guards";
  import { useFormLogger } from "../../composables/use-form-logger.js";
  import { getCustomFormText } from "../../composables/use-custom-data-values.js";

  const LABEL_FOR_THIS_CALLER = "qx-demo-video.vue";

  const props = defineProps<{
    formSettingsKey: "qx-demo-video"
    widgetData: FormWidgetData<"ContactForm">;
    customData?: Record<string, any> | undefined;
  }>();

  const { logger } = useFormLogger();

  const customFormHeading = computed(() => { return getCustomFormText(props.customData, 'formHeading'); });

  const { FormState } = useFormServices({ callerLabel: LABEL_FOR_THIS_CALLER });
  const showUnlockedView = computed(() => {
    return FormState.value === "RegularFormComplete" || FormState.value === "BlindForm";
  });

  const afterCompleteHandler = () => {
    // Removes class from JS that looks if utm_campain exists
    $('.utm-url').removeClass('utm-url');
  };

  watch(showUnlockedView, () => {
    if (showUnlockedView.value === true) {
      afterCompleteHandler();
      $('.eclipse-parent').addClass('success');
    } else {
      $('.eclipse-parent').removeClass('success');
    }
  });
  logger.debug("customFormHeading", { customFormHeading: customFormHeading.value });
</script>

<template>
  <section class="qx-demo">
    <div id="contactScroll" class="qx-wrpr ppc-download dark get-theme" data-widget-theme="dark" data-widget-name="qxDemoBladeWidgetController" data-widget-variation="QxDemo">
      <div class="resource-container row container mx-auto px-5 px-md-0 d-flex justify-content-between">
        <div class="col-12 col-md-6 d-flex flex-column">
          <h1>Demo The #1 Quality Management System</h1>
          <p class="kicker">
            Learn why MasterControl is the #1 Quality Management System for some of the largest Pharma & MedDev companies. More than 1,100 customers trust MasterControl’s Quality Management Software (QMS).
          </p>
          <p class="kicker">
            Watch the eQMS Demo and Learn How You Can:
          </p>
          <ul>
            <li>Optimize Your Document Control and Change Management</li>
            <li>Streamline Your Audit Process</li>
            <li>Develop Seamless Incident and CAPA Management</li>
            <li>Drastically Improve Your Training Cycles</li>
            <li>Eliminate Human Data Entry Errors</li>
            <li>Ensure Regulatory Compliance and Mitigate Risk</li>
          </ul>
        </div>
        <div class="resource-form col-12 col-md-5 offset-md-1 px-0">
          <div id="form-ppc">
            <customResourceForm v-if="!showUnlockedView" 
              :form-settings-key="formSettingsKey"
              :widget-data="widgetData"
              :custom-data="customData"
            >
              <template #heading>
                <h2 class="form-instructions-heading">
                  <template v-if="!isNil(customFormHeading) && customFormHeading != ''">
                    {{ customFormHeading }}
                  </template>
                  <template v-else>
                    Watch the 10-minute QMS demo now!
                  </template>
                </h2>
                <br>
              </template>
            </customResourceForm>
            <template v-else-if="showUnlockedView">
              <div class="success-region after-complete">
                <div class="form-complete d-flex flex-column justify-content-between">
                  <div class="response">
                    <h3>Thank You for Completing the Form</h3>
                    <p>Click the button below to begin the demo.</p>
                    <a class="btn btn-primary stroked js-cta" href="#mcui-modal" data-modal-type="youtube" data-bs-toggle="modal" data-docid="Fub366dV84A" data-sf-ec-immutable="">
                      <span>Watch the Video</span>
                    </a>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>