<script lang="ts">
  // Turn off inheritance through attributes
  export default {
    inheritAttrs: false
  };
</script>

<script setup lang="ts">
  import type { ContactFormSettingsKey } from "../../form-settings/form-experience/form-settings-keys.js";
  import { UserMarketoInputName, type MarketoVals } from "../../types/definitions/inputs/marketo-input-names-and-values.js";
  import { type MarketoUserInputMeta  } from "../../types/definitions/inputs/marketo-input-meta.js";
  import type { FormWidgetData } from "../../../widgetDataTs.js";
  import { useValidations } from "../../composables/use-input-validation.js";
  import {
    useFormServices,
    useInputState,
    useFormSubmit,
    type InputStateOption,
    useEclipseHtmlFormElement,
    useTranslation
  } from "../../composables/use-form-services.js";
  import { useFormUserInputValues } from "../../composables/use-form-user-input-values.js";

  import eclipseInput from "../eclipse-input.vue";
  import submitButton from '../form-experience/submit-button.vue';
  import { TranslationNamespaceEnum } from "../../locale/settings/translation-namespaces.js";
  import type { SubmitButtonTranslationKey } from "../../locale/translation-keys/submit-button-text-keys.js";
  import { ref, watch } from "vue";
  import { isNil, type Nil } from "@mcwd/typescript-type-guards";
  import loaderDark from "../form-experience/loader-icon-dark.vue";
  import formLoadError from "./form-load-error.vue";
  import { useFormLogger } from "../../composables/use-form-logger.js";
  import { GetComputedProperties, type CustomData } from "../../composables/use-custom-data-values.js";

  const props = defineProps<{
    formSettingsKey: ContactFormSettingsKey;
    widgetData: FormWidgetData<"ContactForm">;
    customData?: CustomData | undefined;
    isInModal?: boolean;
  }>();

  const { logger } = useFormLogger();

  const { ctaButtonText, formHeading } = GetComputedProperties(props.customData);

  const { FormSettings, FormClasses, FormState } = useFormServices<"MarketoForm", "subprocessor-update-notification">({ callerLabel: "subprocessor-form-base.vue" });

  const{ validateEmail } = useValidations();

  
  if (!("FullGate" in FormSettings.enabledFields)) {
    throw new Error(`Full Gate does not exist for formExperience '${FormSettings.formSettingsKey}'`);
  }
  const enabledFields = FormSettings.enabledFields.FullGate satisfies readonly string[];
  type EnabledField = typeof enabledFields[number];

  //useInputsMetaData({ callerLabel: "subprocessor-form-base.vue" }) as { FormInputsMeta: Pick<MarketoAllInputMeta, EnabledField> };
  
  //this is a hack to get a different translation
  const LegalEntity = { marketoName: "Company", name: "eclipse_Entity", type: "text", isMarketoInput: true} as unknown as MarketoUserInputMeta;
  const NonBusinessEmail = { marketoName: "Email", name: "eclipse_NonBusinessEmail", type: "email", isMarketoInput: true} as unknown as MarketoUserInputMeta;

  function getEnabledUserFields() {
    return enabledFields.filter(f => (UserMarketoInputName as readonly string[]).includes(f)) as Extract<EnabledField, UserMarketoInputName>[];
  }

  // Currently only handles marketo field names
  const anyEnabledUserField = getEnabledUserFields();
  type AnyEnabledUserField = typeof anyEnabledUserField[number];

  type UserMarketoValues = { [Key in AnyEnabledUserField]: Required<MarketoVals>[Key] };

  const { userInputValues, setFormUserInputValuesFromSettings } = useFormUserInputValues<AnyEnabledUserField, UserMarketoValues>({ callerLabel: "subprocessor form base"});
  setFormUserInputValuesFromSettings();

  const { setInputStates, inputStates } = useInputState<AnyEnabledUserField>({ callerLabel: "subprocessor-form-base.vue" });
  setInputStates(userInputValues, {
    "Email": {
      required: true,
      validator: validateEmail
    },
    "Company": { required: true }
  } satisfies Record<AnyEnabledUserField,InputStateOption>);

  const { submitForm } = useFormSubmit({ callerLabel: "subprocessor-form-base.vue" });
  const { translate } = useTranslation();
  const buttonTextMapKey: SubmitButtonTranslationKey<typeof FormSettings.formSettingsKey> = `${FormSettings.formSettingsKey}-Submit-Button`;

  const formError = window.formLoadError;

  const { setEclipseHtmlFormElement } = useEclipseHtmlFormElement({ callerLabel: "contact-form-base.vue" });
  const formElRef = ref<HTMLFormElement | Nil>(null);

  watch(() => formElRef.value, (formEl) => {
    if (!isNil(formEl)) {
      logger.debug({ formEl });
      if (!isNil(formEl)) {
        setEclipseHtmlFormElement(formEl);
      }
    }
  });
</script>

<template>
  <template v-if="formError">
    <!-- If form does not load -->
    <formLoadError />
  </template>

  <template v-else-if="FormState === 'Loading' || FormState === 'Setup'">
    <!-- Loader for the form -->
    <div class="form-loading">
      <loaderDark />
      <p> Loading Content</p>
    </div>
  </template>

  <template v-else-if="FormState === 'RegularForm'">
    <h3 v-if="formHeading" class="form-header">
      {{ formHeading }}
    </h3>
    <form ref="formElRef" class="eclipse-form single-row" :class="FormClasses.marketoFormClass">
      <eclipseInput v-model="userInputValues.Email"
        :input-state="inputStates.Email"
        :form-input-meta="NonBusinessEmail"
      />
      <eclipseInput v-model="userInputValues.Company"
        :input-state="inputStates.Company"
        :form-input-meta="LegalEntity"
      />
      <!-- Eclipse Submit Button -->
      <submitButton @submit-form="submitForm()">
        {{ ctaButtonText ? ctaButtonText : translate(buttonTextMapKey, { ns: TranslationNamespaceEnum.submitButtonText }) }}
      </submitButton>
    </form>
  </template>
</template>