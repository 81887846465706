import type { FormLabelTranslationsObject } from "../../translation-keys/form-label-keys.js";

const FormLabels: FormLabelTranslationsObject = {
eclipse_FirstName: "Prénom",
eclipse_LastName: "Nom de famille",
eclipse_Email: "Adresse email professionnelle",
eclipse_Company: "Compagnie",
eclipse_Country: "Pays",
eclipse_State: "Territory",
eclipse_Phone: "Téléphone",
eclipse_Tier__c: "Taille de l'entreprise",
eclipse_Industry: "Industrie",
eclipse_Department__c: "Département",
eclipse_contactRequested: "I want someone to call me.",
eclipse_Webform_Comments_recent_value__c: "Comment pouvons-nous vous aider ?",
eclipse_Latest_Solution_Interest__c: "Intérêt de la solution principale",
eclipse_Email_Opt_In__c: `J'aimerais recevoir plus d'informations sur ce sujet, et comprendre et accepter la {privacy-policy-link-slot}`,
privacyStatement: "Nous respectons vos données. Pour savoir comment nous traitons et protégeons vos informations, consultez notre {privacy-policy-link-slot}.",
privacyPolicyLinkText: "Politique de confidentialité",
eclipse_Entity: "",
eclipse_Partner_Opt_in_Processing__c: "I’d like to receive more information on this topic from the MasterControl Partner(s) affiliated with this event.",
eclipse_NonBusinessEmail: "Adresse e-mail",
privacyNewsletterOptIn: ""
};

export default FormLabels;