import {
  type DocumentResource,
  type VideoResource,
  type VideoResourceWithDocs,
  type CreateResourceFormSetup,
  type ModifiersObj,
  type FormWidgetData,
  type CreateGatedVideoFormSetup,
  type BaseFormSetupObject,
} from "../../widgetDataTs.js";

import { BaseFormLogger as logger } from "../base-form-logger.js";
export interface NewDocumentWidgetDataParams {
  formName: string;
  instanceId: string;
  widgetName: string;
  widgetVariation: string;
  selectedListWillBeSingleDocument?: boolean;
  isFilteredOrChosen?: boolean;
  documents: DocumentResource[];
  modifiersObj?: ModifiersObj | undefined;
}

export function newDocumentWidgetData({
  formName,
  instanceId,
  selectedListWillBeSingleDocument,
  isFilteredOrChosen,
  documents,
  widgetName,
  widgetVariation,
  modifiersObj,
}: NewDocumentWidgetDataParams)
{
  logger.debug("New Document WidgetData");
  selectedListWillBeSingleDocument ??= documents.length === 1 && isFilteredOrChosen !== true;
  isFilteredOrChosen ??= false;
  window.AppState.WidgetData.Add<CreateResourceFormSetup>({
    widget: {
      name: widgetName,
      variation: widgetVariation,
      instanceId,
    },
    setup: {
      type: "ResourceForm",
      form: {
        name: formName,
        document: {
          isFilteredOrChosen,
          selectedListWillBeSingleDocument,
          sourceList: documents,
        },
      },
      tracking: {
        usePageOfferId: selectedListWillBeSingleDocument === false,
      },
    },
  });

  return window.AppState.WidgetData.Get(instanceId, modifiersObj) as FormWidgetData<"ResourceForm">;
}

export function newVideoWithDocsWidgetData({
  formName,
  instanceId,
  isFilteredOrChosen,
  videos,
  widgetName,
  widgetVariation,
  modifiersObj,
}: {
  formName: string;
  instanceId: string;
  widgetName: string;
  widgetVariation: string;
  isFilteredOrChosen?: boolean;
  videos: VideoResourceWithDocs[];
  modifiersObj?: ModifiersObj | undefined;
}) {
  isFilteredOrChosen ??= false;
  window.AppState.WidgetData.Add({
    widget: {
      name: widgetName,
      variation: widgetVariation,
      instanceId: instanceId,
    },
    setup: {
      type: "VideoForm",
      form: {
        name: formName,
        video: {
          isFilteredOrChosen,
          sourceList: videos,
        },
        tracking: { usePageOfferId: false },
      },
    },
  });
  return window.AppState.WidgetData.Get(
    instanceId,
    modifiersObj
  ) as FormWidgetData<"VideoForm">;
}

export function newVideoWidgetData({
  formName,
  instanceId,
  isFilteredOrChosen,
  videos,
  widgetName,
  widgetVariation,
  modifiersObj
}: {
  formName: string;
  instanceId: string;
  widgetName: string;
  widgetVariation: string;
  isFilteredOrChosen?: boolean;
  videos: VideoResource[];
  modifiersObj?: ModifiersObj | undefined;
}) {
  isFilteredOrChosen ??= false;
  window.AppState.WidgetData.Add<CreateGatedVideoFormSetup & BaseFormSetupObject<"Create">>({
    widget: {
      name: widgetName,
      variation: widgetVariation,
      instanceId: instanceId,
    },
    setup: {
      type: "GatedVideoForm",
      form: {
        name: formName,
        video: {
          isFilteredOrChosen: isFilteredOrChosen,
          sourceList: videos,
        },
        tracking: { usePageOfferId: false },
      },
    },
  });
  return window.AppState.WidgetData.Get(instanceId, modifiersObj) as FormWidgetData<"GatedVideoForm">;
}

export function newContactWidgetData({
  formName, instanceId, widgetName, widgetVariation, offerId
}: {
  formName: string;
  instanceId: string;
  widgetName: string;
  widgetVariation: string;
  offerId: string
}){
  const setupType = "ContactForm";
  return {
    widget: {
      name: widgetName,
      variation: widgetVariation,
      instanceId: instanceId,
    },
    setup: {
      type: setupType,
      form: {
        name: formName,
      },
      tracking: { offerId },
    },
  } as FormWidgetData<"ContactForm">;
}