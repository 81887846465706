<script lang="ts">
  // Turn off inheritance through attributes
  export default {
    inheritAttrs: false
  };
</script>

<script setup lang="ts">
  import { type FormWidgetData } from "../../../widgetDataTs.js";
  import { watch, computed } from 'vue';
  import resourceForm from "../form-element/resource-form-base.vue";
  import modalBody from "./documentForm/modal-body.vue";
  import multiDocument from "./documentForm/multi-document.vue";
  import multiComplete from "./documentForm/multi-complete.vue";
  import singleDocument from "./documentForm/single-document.vue";
  import singleComplete from "./documentForm/single-complete.vue";
  import resources from "./recommended-resources.vue";
  import { useFormServices } from "../../composables/use-form-services.js";

  const props = defineProps<{
    formSettingsKey: "document";
    widgetData: FormWidgetData<"ResourceForm">;
    isInModal?: boolean,
    customData?: Record<string, any> | undefined;
  }>();

  const documents = computed(() => {
    return props.widgetData.setup.form.document.selected;
  });

  const { FormState, CurrentFormExperienceGatingState } = useFormServices({ callerLabel: "documentForm" });
  const showUnlockedView = computed(() => {
    return FormState.value === "RegularFormComplete" || FormState.value === "BlindForm" || CurrentFormExperienceGatingState.value === "NoGate";
  });
  const ungatedView = computed(()=>{
  return CurrentFormExperienceGatingState.value === 'NoGate';
})
  watch(showUnlockedView, () => {
    if (showUnlockedView.value === true) {
      $('.eclipse-parent').addClass('success');
    } else {
      $('.eclipse-parent').removeClass('success');
    }
  });
</script>

<template>
  <!-- In Modal -->
  <template v-if="isInModal">
    <div id="eclipse-modal-content" class="eclipseBodyContent">
      <!-- Document Body Slot -->
      <modalBody :documents="documents">
        <template v-if="documents.length > 1" #multi-doc>
          <multiComplete v-if="showUnlockedView" :documents="documents" />
          <multiDocument v-else :documents="documents" />
        </template>
        <template v-if="documents.length === 1" #single-doc>
          <singleComplete v-if="showUnlockedView" :document="documents[0]" />
          <singleDocument v-else :document="documents[0]" />
        </template>
        <template v-if="showUnlockedView" #resource>
          <resources :documents="documents" :ungated="ungatedView"/>
        </template>
        <template v-else #form>
          <resourceForm :form-settings-key="formSettingsKey" :widget-data="widgetData" :custom-data="customData" />
        </template>
      </modalBody>
    </div>
  </template>

  <!-- Not in Modal -->
  <template v-else>
    <resourceForm v-if="!showUnlockedView" :form-settings-key="formSettingsKey" :widget-data="widgetData" :documents="documents" :custom-data="customData" />
    <template v-else-if="showUnlockedView">
      <singleComplete :document="documents[0]" />
      <resources :ungated="ungatedView"/>
    </template>
  </template>
</template>