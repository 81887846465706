<script lang="ts">
// Turn off inheritance through attributes
export default {
  inheritAttrs: false
};
</script>

<script setup lang="ts">
import { useFormAssetDownload } from '../../composables/use-form-asset-download.js';
import resourceForm from "../form-element/resource-form-base.vue";
import type { DocumentFormSettingsKey } from "../../form-settings/form-experience/form-settings-keys.js";
import { type FormWidgetData } from "../../../widgetDataTs.js";
import { type Nil, isNil } from "@mcwd/typescript-type-guards";
import { ref, computed, watch } from "vue";
import { useFormServices, useTranslation } from "../../composables/use-form-services.js";
import topContent from "./resourceLibrary/top-content.vue";
import breadCrumbs from './bread-crumbs.vue';
import docCoverImage from './resourceLibrary/doc-cover-image.vue';
import aboutDoc from './resourceLibrary/about-doc.vue';
import bulletList from './resourceLibrary/bullet-list.vue';
import { TranslationNamespaceEnum } from "../../locale/settings/translation-namespaces.js";
import { useFormLogger } from '../../composables/use-form-logger.js';
import { getCustomFormText } from '../../composables/use-custom-data-values.js';

const { downloadResourceTracking } = useFormAssetDownload();

const props = defineProps<{
  formSettingsKey: DocumentFormSettingsKey;
  widgetData: FormWidgetData<"ResourceForm">;
  customData?: Record<string, any> | undefined;
}>();

const { logger } = useFormLogger();

const documents = ref(props.widgetData.setup.form.document.selected);

type LandingPageData = {
  assetType: string,
  heading: string,
  subHeading: string,
  summary: string,
  fileFormat: string,
  fileSize: string,
  coverImage: string,
  breadCrumbList: string,
  bulletList: string
}

function isLandingPageData(value: Record<string, any> | Nil): value is LandingPageData {
  return !isNil(value)
    && ("assetType" in value)
    && ("heading" in value)
    && ("subHeading" in value)
    && ("summary" in value)
    && ("fileFormat" in value)
    && ("fileSize" in value)
    && ("coverImage" in value)
    && ("breadCrumbList" in value)
    && ("bulletList" in value);
}
const data = computed<LandingPageData | Nil>(() => {
  if (isLandingPageData(props.customData)) {
    return props.customData;
  } else {
    logger.warn('CustomData does not match');
    return null;
  }
});

// Removes the utm-url class if form success
const afterCompleteHandler = () => {
  $('.utm-url').removeClass('utm-url');
};

const { FormState, CurrentFormExperienceGatingState } = useFormServices({ callerLabel: "documentForm" });
const showUnlockedView = computed(() => {
  return FormState.value === "RegularFormComplete" || FormState.value === "BlindForm" || CurrentFormExperienceGatingState.value === "NoGate";
});
const showBlindUnlockedView = computed(() => {
  return FormState.value === "BlindForm";
});
const ungatedView = computed(()=>{
  return CurrentFormExperienceGatingState.value === 'NoGate';
})

watch(showUnlockedView, () => {
  if (showUnlockedView.value) {
    afterCompleteHandler();
  }
}, { immediate: true });

const { translate } = useTranslation();

const customFormHeading = computed(() => { return getCustomFormText(props.customData, 'formHeading'); });
</script>

<template>
  <template v-if="!isNil(data)">
    <!-- Top Content -->
    <topContent :custom-data="data">
      <!-- Breadcrumbs -->
      <template #breadcrumb>
        <breadCrumbs :custom-data="data" />
      </template>
    </topContent>
    <!-- Doc Image -->
    <div class="doc-image">
      <docCoverImage :custom-data="data" />
    </div>
    <template v-if="!showUnlockedView">
      <!-- Resource Form -->
      <div id="doc-form" class="doc-form">
        <div id="document-landing-page-form-wrapper">
          <div class="mcui-marketo-form-root js-formBuilderRoot mc-document-landing-page-form">
            <resourceForm :form-settings-key="formSettingsKey" :widget-data="widgetData" :custom-data="customData">
              <template #heading>
                <h5 class="form-instructions-heading">
                  {{ customFormHeading ?? translate("document-landing-page-Heading", {
                    ns: TranslationNamespaceEnum.formHeadingText
                  }) }}
                </h5>
              </template>
            </resourceForm>
          </div>
        </div>
      </div>
      <!-- About this Doc -->
      <aboutDoc :custom-data="data" />
      <!-- Key Takeaways -->
      <bulletList :custom-data="data" />
    </template>

    <!-- Thank You -->
    <div v-else class="thanks-message eclipse-thanks">
      <template v-if="showBlindUnlockedView">
        <h5 class="title">
          {{ translate("document-landing-page-BlindSubmitHeading", {ns: TranslationNamespaceEnum.postSubmitText }) }}
        </h5>
        <p class="copy">
          {{ translate("document-landing-page-BlindSubmitBody", {ns: TranslationNamespaceEnum.postSubmitText }) }}
        </p>
      </template>
      <template v-else>
        <h5 class="title">
          {{ translate("document-landing-page-Heading", {ns: TranslationNamespaceEnum.postSubmitText }) }}
        </h5>
        <p class="copy" v-if="!ungatedView">
          {{ translate("document-landing-page-Body", {ns: TranslationNamespaceEnum.postSubmitText }) }}
        </p>
      </template>
      <div v-for="doc in documents" :key="doc.legacyId" class="download-btn">
        <a aria-label="Download Now" class="btn btn-primary"
          :href="'/customapi/DocumentDownload/GetDocument/' + doc.legacyId + '?lang=' + doc.language"
          @click="downloadResourceTracking({ asset: doc, ctaType: 'button', ctaText: 'Download Now' })">
          <i class="far fa-arrow-to-bottom"></i>
          <span class="js-cta-text-element">{{ translate("document-Download", {ns: TranslationNamespaceEnum.postSubmitText }) }}</span>
        </a>
      </div>
    </div>

  </template>
</template>
