<script lang="ts">
  // Turn off inheritance through attributes
  export default {
    inheritAttrs: false
  };
</script>

<script setup lang="ts">
  import type { FormWidgetData } from '../../../widgetDataTs.js';
  import type { ContactFormSettingsKey } from "../../form-settings/form-experience/form-settings-keys.js";
  import SimpleContactForm from "../form-element/simple-contact-form.vue";
  import { useFormServices, useTranslation } from "../../composables/use-form-services.js";
  import { computed } from "vue";
  import { TranslationNamespaceEnum } from "../../locale/settings/translation-namespaces.js";
  import { ContactFormPostKeysEnum } from "../../locale/translation-keys/all-keys.js";

  defineProps<{
    formSettingsKey: ContactFormSettingsKey;
    widgetData: FormWidgetData<"ContactForm">;
    customData?: Record<string, any> | undefined;
  }>();
  const { FormState } = useFormServices({ callerLabel: "subscriptionForm" });

  const showUnlockedView = computed(() => {
    return FormState.value === "RegularFormComplete";
  });

  const { translate } = useTranslation();
</script>

<template>
  <template v-if="!showUnlockedView">
    <SimpleContactForm :form-settings-key="formSettingsKey" :widget-data="widgetData" :custom-data="customData" />
  </template>
  <template v-else>
    <div class="form-completion-message after-complete">
      <img class="email" src="https://static.mastercontrol.com/assets/persist/images/mcui-resources-sent.png" alt="email sent">
      <h2 class="complete">{{ translate(ContactFormPostKeysEnum.subscriptionMessage, {ns: TranslationNamespaceEnum.contactPostSubmit}) }}</h2>
    </div>
  </template>
</template>