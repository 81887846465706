<script setup lang="ts">
  import { isString, type Nil } from '@mcwd/typescript-type-guards';
  import { computed, ref } from 'vue';

  const props = defineProps<{
    src?: string | Nil,
    fallbackSrc: string,
    alt?: string | Nil
  }>();

  // Set when the fallback image should be used.
  const imageFallbackSrc = ref<string | null>(null);

  // Returns the image src or the fallback image.
  const activeImageSrc = computed(() => {
    if (isString(imageFallbackSrc.value)) {
      return imageFallbackSrc.value; 
    }
    else {
      return props.src ?? props.fallbackSrc;
    }
  });

  function useFallbackImage() {
    imageFallbackSrc.value = props.fallbackSrc;
  }
</script>

<template>
  <img
    :src="activeImageSrc"
    :alt="(alt ?? '')"
    width="160"
    height="207"
    @error.once="useFallbackImage()"
  >
</template>