import { createApp, type App as VueApp } from "vue";

import {
  excludeFrom,
  isArray,
  isBoolean,
  isNil,
  isObject,
  isString,
  isStringLiteralOfType,
  type ExpandShallow,
  type Nil,
  type PartialWithNil,
} from "@mcwd/typescript-type-guards";

import {
  AnyFormSettingsKey,
  ContactFormSettingsKey,
  DocumentFormSettingsKey,
  VideoFormSettingsKey,
  CustomResourceFormSettingsKey
} from "./form-settings/form-experience/form-settings-keys.js";

import { FormExperienceSettingsObject } from "./form-settings/form-settings-factory.js";

import { useI18NextVue } from "./locale/initialize-translations.js";

import { BaseFormLogger as baseFormLogger, SecondButtonDefaultStyle } from "./base-form-logger.js";

import {
  FormSetupType,
  isFormWidgetData,
  type FormWidgetData,
  type DocumentResource,
  type ModifiersObj,
  type VideoResourceWithDocs,
  type VideoResource,
  isVideoArray,
  isVideoWithDocsArray,
} from "../widgetDataTs.js";

import { newDocumentWidgetData, newVideoWidgetData, newVideoWithDocsWidgetData } from "./form-logic/widget-data-factory.js";

import type { SiteCountryCode } from "../types-and-definitions/locale-defs/Locales.js";

// Something is wrong with typescript getting types from vue, so manually define the types.
import { default as __AppForm } from './components/App-Form.vue';
import type { AppFormComponentProps, AppFormType } from "./components/App-Form-Types.js";
import type { ILogger } from "../utils/Logger/ILogger.js";
const AppForm: AppFormType = __AppForm as unknown as AppFormType;

type VueData = Parameters<typeof createApp>[1];

export type InitFormAppResult = {
  appInstance: VueApp<Element>;
  componentInstance: AppFormType;
};

/******************************  FormSettingsInitKeys  **************************************/
const SpecializedInitFormSettingsKey = [
  "digital-maturity-assessment",
  "event-registration",
  "partner",
  "post-event-assets",
  "pricing",
  "qx-roi",
  "mx-roi",
  "subprocessor-update-notification",
  "subscription"
] as const;
type SpecializedInitFormSettingsKey = typeof SpecializedInitFormSettingsKey[ number ];


export const InitContactFormSettingsKey = excludeFrom(ContactFormSettingsKey, ...SpecializedInitFormSettingsKey);
export const InitDocumentFormSettingsKey = excludeFrom(DocumentFormSettingsKey, ...SpecializedInitFormSettingsKey);
export const InitVideoFormSettingsKey = excludeFrom(VideoFormSettingsKey, ...SpecializedInitFormSettingsKey);

export type InitContactFormSettingsKey = typeof InitContactFormSettingsKey[ number ];
export type InitDocumentFormSettingsKey = typeof InitDocumentFormSettingsKey[ number ];
export type InitVideoFormSettingsKey = typeof InitVideoFormSettingsKey[ number ];



type InitializeFormBase<TSettingsKey extends AnyFormSettingsKey, TAdditionalProperties extends object = {}> = ExpandShallow<
  {
    formSettingsKey: TSettingsKey;
    rootContainer: string | HTMLElement;
    customData?: Record<string, any> | undefined;
    isInModal?: boolean | Nil;
    lang?: SiteCountryCode;
  } &
  TAdditionalProperties
>

type InitializeFormBaseWithWidgetData<
  TSettingsKey extends AnyFormSettingsKey,
  TWidgetData extends FormWidgetData,
  TAdditionalProperties extends object = {}
> = InitializeFormBase<
  TSettingsKey,
  { widgetData: TWidgetData } &
  TAdditionalProperties
>;

function startLogTimers(formSettingsKey: AnyFormSettingsKey, logger: ILogger) {
  logger.time(`${formSettingsKey}-mount`);
  logger.time(`${formSettingsKey}-beforeUpdate`);
  logger.time(`${formSettingsKey}-ready`);
  logger.time(`${formSettingsKey}-from-init-to-first-render`);
}

function parseLang(lang: SiteCountryCode | Nil) {
  return lang ?? window.AppState.GetCurrentLanguageCode().toLocaleLowerCase() as SiteCountryCode;
}

function parseFormSettingsKey<TFormSettingsKey extends AnyFormSettingsKey>(
  formSettingsKey: TFormSettingsKey,
  validFormSettingsKeys: readonly TFormSettingsKey[]
): TFormSettingsKey {
  if (!formSettingsKey || validFormSettingsKeys.includes(formSettingsKey) === false) {
    if (formSettingsKey) {
      throw new Error(
        `formSettingsKey should be one of the following values [${validFormSettingsKeys.toString()}]`
      );
    } else {
      throw new Error("formSettingsKey cannot be null!");
    }
  }
  return formSettingsKey;
}

function getLogger(formSettingsKey: AnyFormSettingsKey) {
  return baseFormLogger.getChildLogger({
    tags: { formStage: "initialize" },
    prefixStrings: [formSettingsKey],
    prefixStyles: [SecondButtonDefaultStyle]
  });
}

const MultiDocumentOfferId = "7013x000002b6j7AAA" as const;

function parseWidgetData<TSetup extends FormSetupType>(widgetData: FormWidgetData<TSetup> | Nil, setupType: TSetup | TSetup[], logger: ILogger) {
  if (isNil(widgetData)) {
    throw new Error("widgetData parameter is required!");
  }
  else if (isFormWidgetData(widgetData, setupType, true) === false) {
    throw new Error(
      "widgetData parameter is the wrong type or doesn't have complete data"
    );
  }
  if (widgetData.setup.type === "ResourceForm" || widgetData.setup.type === "VideoForm") {
    const usePageOfferId = widgetData.setup.tracking.usePageOfferId;
    const hasMultipleDocs = widgetData.setup.form.document.selected.length > 1;
    if (usePageOfferId || hasMultipleDocs) {
      const offerId = widgetData.setup.tracking.offerId || MultiDocumentOfferId;

      logger.info({ 
        ...(isBoolean(usePageOfferId) ? { usePageOfferId } : {}),
        ...(hasMultipleDocs ? { hasMultipleDocs } : {}),
        offerId: MultiDocumentOfferId
      });
      
      widgetData.setup.tracking.offerId = offerId;
      if (!hasMultipleDocs && offerId === MultiDocumentOfferId) {
        if (widgetData.setup.type === "ResourceForm") {
          logger.info("Multi document form offerId used for single document (however, this may still be a multi document form)");
        }
        else {
          logger.warn("Unexpected MutliDocument offerId!");
        }
      }
    }
  }
  if (!isString(widgetData.setup.tracking.offerId) || widgetData.setup.tracking.offerId.trim().length === 0) {
    logger.warn("offerId is not set in widgetData", widgetData);
  }
  return widgetData;
}

function parseIsInModal(formSettingsKey: AnyFormSettingsKey, isInModal: boolean | Nil, logger: ILogger) {
  if(isInModal === true && !FormExperienceSettingsObject[formSettingsKey].canUseModal) {
    logger.warn("isInModal not enabled");
  }
  isInModal = isInModal === true && FormExperienceSettingsObject[formSettingsKey].canUseModal;
  logger.debug("isInModal", isInModal);
  return isInModal;
}

function getRootElement(rootContainer: string | HTMLElement): HTMLElement {
  if (isNil(rootContainer)) {
    throw new Error(`Parameter "rootContainer" in form initialize function is null or was not provided!`);
  }
  const rootContainerSelector = isString(rootContainer) ? rootContainer : "";
  const resolvedRootContainer = isString(rootContainer) ? document.querySelector<HTMLElement>(rootContainerSelector) : rootContainer;
  if (isNil(resolvedRootContainer)) {
    throw new Error(`Couldn't find selector '${rootContainerSelector ?? ""}' in document`);
  }
  return resolvedRootContainer;
}

async function createAndMountApp (
  rootContainer: string | HTMLElement,
  data: AppFormComponentProps,
  logger: ILogger
): Promise<InitFormAppResult>
{
  const app = createApp(AppForm, data as unknown as VueData);
  await useI18NextVue(app, data.lang, logger);
  const componentInstance = app.mount(rootContainer) as unknown as AppFormType;
  app.config.errorHandler = (err) => {
    logger.error(err);
  };
  return { appInstance: app, componentInstance };
}

export type WidgetMeta = {
  name: string,
  instanceId: string,
  variation: string
};

export type InitializeContactFormParameters = InitializeFormBaseWithWidgetData<
  InitContactFormSettingsKey,
  FormWidgetData<"ContactForm">
>;


export type InitializeDocFormWithWidgetData = InitializeFormBaseWithWidgetData<
  InitDocumentFormSettingsKey,
  FormWidgetData<"ResourceForm">
>;

export type InitializeDocFormWithDocuments = InitializeFormBase<
  InitDocumentFormSettingsKey,
  {
    widget: WidgetMeta;
    documents: DocumentResource[];
    selectedListWillBeSingleDocument?: boolean;
    isFilteredOrChosen?: boolean;
    modifiersObj?: ModifiersObj;
  }
>;


export type InitializeDocFormWithLegacyId = InitializeFormBase<
  InitDocumentFormSettingsKey,
  {
    widget: WidgetMeta;
    legacyId: string;
    selectedListWillBeSingleDocument?: boolean;
    isFilteredOrChosen?: boolean;
    modifiersObj?: ModifiersObj;
  }
>;


export type InitializeDocumentFormParameters =
  | InitializeDocFormWithWidgetData
  | InitializeDocFormWithDocuments
  | InitializeDocFormWithLegacyId;


export async function initializeDocumentForm (options: InitializeDocumentFormParameters) {
  const { customData } = options;
  let { rootContainer, formSettingsKey, widgetData, isInModal, lang } = options as InitializeDocumentFormParameters & { widgetData?: FormWidgetData<"ResourceForm"> };
  formSettingsKey = parseFormSettingsKey(formSettingsKey, InitDocumentFormSettingsKey);

  const logger = getLogger(formSettingsKey);
  logger.debug("initializeDocumentForm => options: ", options);
  console.log("This is the options", options);
  startLogTimers(options.formSettingsKey, logger);

  rootContainer = getRootElement(rootContainer);
  isInModal = parseIsInModal(formSettingsKey, isInModal, logger);
  lang = parseLang(lang);
  const formSettings = FormExperienceSettingsObject[formSettingsKey];

  if (isNil(widgetData)) {
    logger.info("widgetData is nil");
    const formName = formSettings.formTrackingName;
    let { documents } = options as PartialWithNil<InitializeDocFormWithDocuments>;
    const { legacyId, widget, modifiersObj } = options as Partial<InitializeDocFormWithDocuments & InitializeDocFormWithLegacyId>;

    if (isNil(widget)) {
      throw new Error("Property widget cannot be null if widgetData is not provided.");
    }
    const { instanceId, name: widgetName, variation: widgetVariation } = widget;
    if (isString(formSettingsKey) && isString(formName) && isString(instanceId) && isString(widgetName) && isString(widgetVariation)) {
      if (isNil(documents) && isString(legacyId)) {
        logger.debug("Getting documents with legacyId: ", legacyId);
        documents = await window.AjaxRepo.ResourceWidgetData.GetDocumentData(legacyId, lang);
        if (isNil(documents)) {
          throw new Error(
            `Couldn't find document with legacyId: "${legacyId}"`
          );
        }
      }

      logger.debug("documents", documents);

      if (documents === null) {
        throw new Error("Provide either the documents or legacyId parameter!");
      }

      if (!isArray(documents)) {
        throw new Error("documents is not an array!");
      }
      widgetData = newDocumentWidgetData({ formName, instanceId, widgetName, widgetVariation, documents, modifiersObj });
    }
    else {
      logger.info("One of the following properties is not a string: ", { formSettingsKey, formName, instanceId, widgetName, widgetVariation });
    }
  }
  widgetData = parseWidgetData(widgetData, "ResourceForm", logger);
  const data: AppFormComponentProps = {
    formSettingsKey,
    widgetData,
    isInModal,
    customData,
    lang,
  };
  return createAndMountApp(rootContainer, data, logger);
}

export type InitializeVideoFormWithWidgetData = InitializeFormBaseWithWidgetData<
  InitVideoFormSettingsKey,
  FormWidgetData<"VideoForm" | "GatedVideoForm">
>;


export type InitializeVideoFormWithVideos = InitializeFormBase<
  InitVideoFormSettingsKey,
  {
    widget: WidgetMeta;
    videos: VideoResource[] | VideoResourceWithDocs[];
    modifiersObj?: ModifiersObj;
  }
>;

export type InitializeVideoFormWithLegacyId = InitializeFormBase<
  InitVideoFormSettingsKey,
  {
    widget: WidgetMeta;
    legacyId: string;
    modifiersObj?: ModifiersObj;
  }
>;

export type InitializeVideoFormParameters =
  | InitializeVideoFormWithWidgetData
  | InitializeVideoFormWithVideos
  | InitializeVideoFormWithLegacyId;


// eslint-disable-next-line complexity
export async function initializeVideoForm(options: InitializeVideoFormParameters) {
  // document.body.style.background = "blue";
  const { customData } = options;
  let { rootContainer, formSettingsKey, widgetData, isInModal, lang } = options as InitializeVideoFormParameters & { widgetData?: FormWidgetData<"VideoForm" | "GatedVideoForm"> };
  formSettingsKey = parseFormSettingsKey(formSettingsKey, InitVideoFormSettingsKey);

  const logger = getLogger(formSettingsKey);
  logger.debug("initializeVideoForm => options: ", options);
  startLogTimers(options.formSettingsKey, logger);

  rootContainer = getRootElement(rootContainer);
  isInModal = parseIsInModal(formSettingsKey, isInModal, logger);
  lang = parseLang(lang);
  const formSettings = FormExperienceSettingsObject[formSettingsKey];

  if (isNil(widgetData)) {
    logger.info("widgetData is nil");
    const formName = formSettings.formTrackingName;
    let { videos } = options as PartialWithNil<InitializeVideoFormWithVideos>;

    const legacyId = ("legacyId" in options) ? options.legacyId : undefined;
    const widget = ("widget" in options) ? options.widget : undefined;
    const modifiersObj = ("modifiersObj" in options) ? options.modifiersObj : undefined;
    if (isNil(widget)) {
      throw new Error("Property widget cannot be null if widgetData is not provided.");
    }
    const { instanceId, name: widgetName, variation: widgetVariation } = widget;
    if (isString(formSettingsKey) && isString(formName) && isString(instanceId) && isString(widgetName) && isString(widgetVariation)) {
      if (isNil(videos) && isString(legacyId)) {
        logger.debug("Getting videos with legacyId: ", legacyId);
        videos = await window.AjaxRepo.ResourceWidgetData.GetVideoData(legacyId, lang);
        if (isNil(videos)) {
          throw new Error(
            `Couldn't find videos with legacyId: "${legacyId}"`
          );
        }
      }

      logger.debug("videos", videos);

      if (videos === null) {
        throw new Error("Provide either the videos or legacyId parameter!");
      }

      if (!isArray(videos)) {
        throw new Error("videos is not an array!");
      }
      if(formSettingsKey === "ppc-video"){
        if(videos.length === 1 && !videos[0].salesforceOfferId){
          throw new Error("SalesForceOfferId required for gated video.");
        }
      }
      if (formSettingsKey === "video") {
        if (isVideoWithDocsArray(videos)) {
          widgetData = newVideoWithDocsWidgetData({ videos, formName, instanceId, widgetName, widgetVariation, modifiersObj });
        }
        else {
          throw new Error(`Property "videos" is not of type VideoResourceWithDocs[]`);
        }
      }
      else {
        if (isVideoArray(videos)) {
          widgetData = newVideoWidgetData({ videos, formName, instanceId, widgetName, widgetVariation, modifiersObj });
        }
        else {
          throw new Error(`Property "videos" is not of type VideoResource[]`);
        }
      }
    }
    else {
      logger.info("One of the following properties is not a string: ", { formSettingsKey, formName, instanceId, widgetName, widgetVariation });
    }
  }

  widgetData = parseWidgetData(widgetData, ["VideoForm", "GatedVideoForm"], logger);
  const data: AppFormComponentProps = {
    formSettingsKey,
    widgetData,
    customData,
    isInModal,
    lang
  };

  return createAndMountApp(rootContainer, data, logger);
}


export type InitializeCustomResourceFormParameters = InitializeFormBaseWithWidgetData<
  CustomResourceFormSettingsKey,
  FormWidgetData<"ContactForm">
>;

export function initializeCustomResourceForm(options: InitializeCustomResourceFormParameters) {
  const {customData} = options;
  let { rootContainer, formSettingsKey, widgetData, isInModal, lang } = options;
  formSettingsKey = parseFormSettingsKey(formSettingsKey, CustomResourceFormSettingsKey);

  const logger = getLogger(formSettingsKey);
  logger.debug("initializeCustomResourceForm => options: ", options);
  startLogTimers(options.formSettingsKey, logger);

  rootContainer = getRootElement(rootContainer);
  isInModal = parseIsInModal(formSettingsKey, isInModal, logger);
  widgetData = parseWidgetData(widgetData, "ContactForm", logger);
  lang = parseLang(lang);
  const data: AppFormComponentProps = {
    formSettingsKey,
    widgetData,
    isInModal,
    customData,
    lang,
  };
  return createAndMountApp(rootContainer, data, logger);
}
export function initializeContactForm(options: InitializeContactFormParameters) {
  const {customData} = options;
  let { rootContainer, formSettingsKey, widgetData, isInModal, lang } = options;
  formSettingsKey = parseFormSettingsKey(formSettingsKey, InitContactFormSettingsKey);

  const logger = getLogger(formSettingsKey);
  logger.debug("initializeContactForm => options: ", options);
  startLogTimers(options.formSettingsKey, logger);

  rootContainer = getRootElement(rootContainer);
  isInModal = parseIsInModal(formSettingsKey, isInModal, logger);
  widgetData = parseWidgetData(widgetData, "ContactForm", logger);
  lang = parseLang(lang);
  const data: AppFormComponentProps = {
    formSettingsKey,
    widgetData,
    isInModal,
    customData,
    lang,
  };
  return createAndMountApp(rootContainer, data, logger);
}


export type InitializePostEventFormParameters = InitializeFormBaseWithWidgetData<
  "post-event-assets",
  FormWidgetData<"PostEventForm">
>;

export function initializePostEventForm(options: InitializePostEventFormParameters) {
  const { customData } = options;
  let { rootContainer, formSettingsKey, widgetData, lang } = options;
  formSettingsKey = parseFormSettingsKey(formSettingsKey, ["post-event-assets"]);

  const logger = getLogger(formSettingsKey);
  logger.debug("initializePostEventForm => options: ", options);
  startLogTimers(options.formSettingsKey, logger);

  rootContainer = getRootElement(rootContainer);
  widgetData = parseWidgetData(widgetData, ["PostEventForm"], logger);
  lang = parseLang(lang);
  const data: AppFormComponentProps = {
    formSettingsKey,
    widgetData,
    customData,
    lang,
  };
  return createAndMountApp(rootContainer, data, logger);
}

export type InitializePartnerFormParameters = InitializeFormBaseWithWidgetData<
  "partner",
  FormWidgetData<"ContactForm">
>;

export function initializePartnerForm(options: InitializePartnerFormParameters) {
  let { rootContainer, formSettingsKey, widgetData, lang } = options;
  formSettingsKey = parseFormSettingsKey(formSettingsKey, ["partner"]);
  const {customData} = options;
  const logger = getLogger(formSettingsKey);
  logger.debug("initializePartnerForm => options: ", options);
  startLogTimers(options.formSettingsKey, logger);

  rootContainer = getRootElement(rootContainer);
  lang = "us";
  widgetData ??= {
    widget: {
      name: "Partner Form",
      variation: "",
      instanceId: "741a24d8-fbc0-4a43-963d-c555274c1cb2"
    },
    setup: {
      type: "ContactForm",
      form: {
        name: "Partner Form",
      },
      tracking: {
        usePageOfferId: false,
        offerId: "",
      }
    }
  };
  widgetData = parseWidgetData(widgetData, "ContactForm", logger);
  const data: AppFormComponentProps = {
    formSettingsKey,
    widgetData: widgetData,
    customData,
    lang,
  };
  return createAndMountApp(rootContainer, data, logger);
}
export type InitializePricingFormParameters = InitializeFormBaseWithWidgetData<
  "pricing",
  FormWidgetData<"ContactForm">
>;

export function initializePricingForm(options: InitializePricingFormParameters) {
  let { rootContainer, formSettingsKey, widgetData, lang } = options;
  formSettingsKey = parseFormSettingsKey(formSettingsKey, ["pricing"]);
  const { customData } = options;
  const logger = getLogger(formSettingsKey);
  logger.debug("initializePricingForm => options: ", options);
  startLogTimers(options.formSettingsKey, logger);

  rootContainer = getRootElement(rootContainer);
  lang = parseLang(lang);
  widgetData ??= {
    widget: {
      name: "Pricing Form",
      variation: "",
      instanceId: ""
    },
    setup: {
      type: "ContactForm",
      form: {
        name: "Pricing Form",
      },
      tracking: {
        usePageOfferId: false,
        offerId: "7013x000002awzeAAA",
      }
    }
  };
  widgetData = parseWidgetData(widgetData, "ContactForm", logger);
  const data: AppFormComponentProps = {
    formSettingsKey,
    widgetData: widgetData,
    customData,
    lang,
  };
  return createAndMountApp(rootContainer, data, logger);
}

export type InitializeSubscriptionFormParameters = InitializeFormBaseWithWidgetData<
  "subscription",
  FormWidgetData<"ContactForm">
>;


export function initializeSubscriptionForm(options: InitializeSubscriptionFormParameters) {
  let { rootContainer, formSettingsKey, widgetData, lang } = options;
  formSettingsKey = parseFormSettingsKey(formSettingsKey, ["subscription"]);
  const { customData } = options;
  const logger = getLogger(formSettingsKey);
  logger.debug("initializeSubscriptionForm => options: ", options);
  startLogTimers(options.formSettingsKey, logger);

  rootContainer = getRootElement(rootContainer);
  lang = parseLang(lang);
  widgetData ??= {
    widget: {
      name: "Subscription Form",
      variation: "",
      instanceId: ""
    },
    setup: {
      type: "ContactForm",
      form: {
        name: "Subscription Form",
      },
      tracking: {
        usePageOfferId: false,
        offerId: "7013x000002dZdqAAE",
      }
    }
  };
  widgetData = parseWidgetData(widgetData, "ContactForm", logger);
  const data: AppFormComponentProps = {
    formSettingsKey,
    widgetData: widgetData,
    customData,
    lang,
  };
  return createAndMountApp(rootContainer, data, logger);
}

export type InitializeSubprocessorFormParameters = InitializeFormBaseWithWidgetData<
  "subprocessor-update-notification",
  FormWidgetData<"ContactForm">
>;

export function initializeSubprocessorForm(options: InitializeSubprocessorFormParameters) {
  let { rootContainer, formSettingsKey, widgetData, lang } = options;
  const {customData} = options;
  formSettingsKey = parseFormSettingsKey(formSettingsKey, ["subprocessor-update-notification"]);

  const logger = getLogger(formSettingsKey);
  logger.debug("initializeSubprocessorForm => options: ", options);
  startLogTimers(options.formSettingsKey, logger);

  rootContainer = getRootElement(rootContainer);
  lang = parseLang(lang);
  widgetData ??= {
    widget: {
      name: "Subprocessor Form",
      variation: "",
      instanceId: ""
    },
    setup: {
      type: "ContactForm",
      form: {
        name: "Subprocessor Form",
      },
      tracking: {
        usePageOfferId: false,
        offerId: "",
      }
    }
  };
  widgetData = parseWidgetData(widgetData, ["ContactForm"], logger);
  const data: AppFormComponentProps = {
    formSettingsKey,
    widgetData,
    customData,
    lang,

  };
  return createAndMountApp(rootContainer, data, logger);
}
export type InitializeEventFormParameters = InitializeFormBaseWithWidgetData<
  "event-registration",
  FormWidgetData<"ContactForm">
>;

export async function initializeEventRegistrationForm(options: InitializeEventFormParameters) {
  let { formSettingsKey, widgetData, rootContainer, lang } = options;
  const { customData, isInModal } = options;
  formSettingsKey = parseFormSettingsKey(formSettingsKey, ["event-registration"]);
  
  const logger = getLogger(formSettingsKey);
  logger.debug("initializeEventRegistrationForm => options: ", options);
  startLogTimers(options.formSettingsKey, logger);

  rootContainer = getRootElement(rootContainer);
  lang = parseLang(lang);
  widgetData = parseWidgetData(widgetData, ["ContactForm"], logger);
  const data : AppFormComponentProps = {
    formSettingsKey,
    widgetData, 
    customData,
    isInModal,
    lang,
  };
  return await createAndMountApp(rootContainer, data, logger);

}

export type InitializeRoiFormParameters = InitializeFormBase<
  "qx-roi" | "mx-roi", 
  {
    offerId: string;
    formName: string;
  }
>;

export function initializeRoiForm(options: InitializeRoiFormParameters) {
  const { offerId, formName, isInModal, customData } = options;
  let { rootContainer, formSettingsKey, lang } = options;
  formSettingsKey = parseFormSettingsKey(formSettingsKey, ["qx-roi", "mx-roi"]);
  
  const logger = getLogger(formSettingsKey);
  logger.debug("initializeRoiForm => options: ", options);
  startLogTimers(options.formSettingsKey, logger);
  
  rootContainer = getRootElement(rootContainer);
  lang = "us";
  if(!isString(offerId)) {
    throw new Error("Offer ID is required");
  }
  if(!isString(formName)) {
    throw new Error("Form Name is required");
  }
  let widgetData: FormWidgetData<"ContactForm"> = {
    widget: {
      name: "Roi Form",
      variation: "",
      instanceId: "roi form instance id"
    },
    setup: {
      type: "ContactForm",
      form: {
        name: formName,
      },
      tracking: {
        usePageOfferId: false,
        offerId: offerId,
      }
    }
  };
  widgetData = parseWidgetData(widgetData, "ContactForm", logger);
  const data: AppFormComponentProps = {
    formSettingsKey,
    widgetData: widgetData,
    lang,
    isInModal,
    customData
  };
  return createAndMountApp(rootContainer, data, logger);
}

export type InitializeMaturityFormParameters = InitializeFormBaseWithWidgetData<
  "digital-maturity-assessment",
  FormWidgetData<"MaturityForm">,
  {
    formName: string;
    offerId: string;
  }
>;

export function initializeMaturityForm(options: InitializeMaturityFormParameters){
    let { rootContainer, formSettingsKey, lang } = options;
    const { isInModal, widgetData, customData } = options;
    formSettingsKey = parseFormSettingsKey(formSettingsKey, ["digital-maturity-assessment"]);
    const logger = getLogger(formSettingsKey);
    logger.debug("initializeMaturityForm => options: ", options);
    startLogTimers(options.formSettingsKey, logger);
  
    rootContainer = getRootElement(rootContainer);
    lang = parseLang(lang);
    const data: AppFormComponentProps = {
      formSettingsKey,
      lang,
      isInModal,
      widgetData,
      customData
    };
    return createAndMountApp(rootContainer, data, logger);

}
/**
 * Forms to Initializers MAP
 * 
 * Contact Forms: initializeContactForm         
 * Demo Request Forms: initializeContactForm
 * Document Forms: initializeDocumentForm OR form-cta-launcher
 * Document Landing Page Forms: initializeDocumentForm
 * Event Registration Forms: initializeEventRegistrationForm
 * Partner Forms: initializePartnerForm
 * Post Events Forms: initializePostEventForm
 * PPC Contact:
 * PPC Form: initializeDocumentForm
 * Pricing Forms: initializePricingForm
 * RIO Forms: initializeRoiForm
 * Spark Forms:
 * Subprocessor Forms: initializeSubprocessorForm
 * Subscription Forms: initializeSubscriptionForm
 * TradeShow Giveaway Forms: initializeContactForm
 * Video Forms: form-cta-launcher
 * Video Landing Page Forms: initializeVideoForm
 * Digital Maturity Assessment: initializeMaturityAssessment
 * 
 */



type AnyInitializeFormParameters =  
  | InitializeContactFormParameters
  | InitializeDocumentFormParameters
  | InitializeVideoFormParameters
  | InitializeEventFormParameters
  | InitializeCustomResourceFormParameters
  | InitializePostEventFormParameters
  | InitializePartnerFormParameters
  | InitializePricingFormParameters
  | InitializeSubscriptionFormParameters
  | InitializeSubprocessorFormParameters
  | InitializeRoiFormParameters
  | InitializeMaturityFormParameters;

type _GetInitializeFormParametersBySettingsKey<TFormSettingsKey extends AnyFormSettingsKey, TInitParams extends AnyInitializeFormParameters = AnyInitializeFormParameters> =
  TInitParams extends any
    ? (
      TFormSettingsKey extends TInitParams["formSettingsKey"]
        ? TInitParams
        : never
    )
    : never;

export type GetInitializeFormParametersBySettingsKey<TFormSettingsKey extends AnyFormSettingsKey> =
  _GetInitializeFormParametersBySettingsKey<TFormSettingsKey>;

type _FormSettingsKeyToInitFunctionPossibleMapping = {
  [ K in AnyFormSettingsKey ]: [ GetInitializeFormParametersBySettingsKey<K> ] extends [ infer TParams extends any ]
    ? (options: TParams) => Promise<InitFormAppResult>
    : never;
}

const FormSettingsKeyToInitFunctionMapping = {
  "case-study": initializeDocumentForm,
  "contact": initializeContactForm,
  "demo-request": initializeContactForm,
  "digital-maturity-assessment": initializeMaturityForm,
  "document": initializeDocumentForm,
  "document-landing-page": initializeDocumentForm,
  "event-registration": initializeEventRegistrationForm,
  "mx-roi": initializeRoiForm,
  "partner": initializePartnerForm,
  "post-event-assets": initializePostEventForm,
  "ppc": initializeDocumentForm,
  "ppc-contact": initializeContactForm,
  "ppc-video": initializeVideoForm,
  "pricing": initializePricingForm,
  "qx-demo-video": initializeCustomResourceForm,
  "qx-roi": initializeRoiForm,
  "spark": initializeContactForm,
  "subprocessor-update-notification": initializeSubprocessorForm,
  "subscription": initializeSubscriptionForm,
  "tradeshow-giveaway": initializeContactForm,
  "video": initializeVideoForm,
  "video-gated": initializeVideoForm,
  "video-landing-page": initializeVideoForm,
} satisfies _FormSettingsKeyToInitFunctionPossibleMapping;
type FormSettingsKeyToInitFunctionMapping = typeof FormSettingsKeyToInitFunctionMapping;

function getPrimitiveName (value: unknown) {
  return value === null ? "null" : typeof value;
}


type GetFormInitializationFunctionFromSettingsKey<TFormSettingsKey extends AnyFormSettingsKey> =
  [ GetInitializeFormParametersBySettingsKey<TFormSettingsKey> ] extends [ infer TInitParams ]
    ? ((options: TInitParams) => Promise<InitFormAppResult>)
    : never;

function getFormInitializationFunctionFromOptions<TFormSettingsKey extends AnyFormSettingsKey> (
  options: GetInitializeFormParametersBySettingsKey<TFormSettingsKey>
): GetFormInitializationFunctionFromSettingsKey<TFormSettingsKey>
{
  if (!isObject(options)) {
    throw new Error(`Parameter 'options' is required to be an object! Received type '${ getPrimitiveName(options) }'`);
  }
  if (!("formSettingsKey" in options)) {
    throw new Error(`Required Property 'formSettingsKey' of Parameter 'options' was not defined!`);
  }
  if (!isString(options.formSettingsKey)) {
    throw new Error(`Property 'formSettingsKey' of Parameter 'options' was expected to be a string! Received ${ getPrimitiveName(options.formSettingsKey) }`);
  }
  if (!isStringLiteralOfType(options.formSettingsKey, AnyFormSettingsKey)) {
    throw new Error(`Property 'formSettingsKey' of Parameter 'options' was expected to be of type AnyFormSettingsKey! Received ${ options.formSettingsKey as string }`);
  }
  if (!(options.formSettingsKey in FormSettingsKeyToInitFunctionMapping)) {
    throw new Error(`Initialization function not found for formSettingsKey "${ options.formSettingsKey }"! Has it been added to the mapping?`);
  }
  return FormSettingsKeyToInitFunctionMapping[ options.formSettingsKey ] as unknown as GetFormInitializationFunctionFromSettingsKey<TFormSettingsKey>;
}


export function initializeForm<TFormSettingsKey extends AnyFormSettingsKey> (options: GetInitializeFormParametersBySettingsKey<TFormSettingsKey>): Promise<InitFormAppResult>
{
  const initFormFn: GetFormInitializationFunctionFromSettingsKey<TFormSettingsKey> = getFormInitializationFunctionFromOptions<TFormSettingsKey>(options);
  return initFormFn(options);
}