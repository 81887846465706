import type { FormLabelTranslationsObject } from "../../translation-keys/form-label-keys.js";

const FormLabels: FormLabelTranslationsObject = {
  eclipse_FirstName: "First Name",
  eclipse_LastName: "Last Name",
  eclipse_Email: "Business Email",
  eclipse_Company: "Company Name",
  eclipse_Country: "Country",
  eclipse_State: "State",
  eclipse_Phone: "Telephone",
  eclipse_Tier__c: "Company Size",
  eclipse_Industry: "Industry",
  eclipse_Department__c: "Department",
  eclipse_contactRequested: "I want someone to call me.",
  eclipse_Webform_Comments_recent_value__c: "How can we help?",
  eclipse_Latest_Solution_Interest__c: "Main Solution Interest",
  eclipse_Email_Opt_In__c: `I'd like to receive more information on this topic, and understand and agree to the {privacy-policy-link-slot}`,
  privacyStatement: "We respect your data. To see how we process and secure it read our {privacy-policy-link-slot}.",
  privacyNewsletterOptIn: "By signing up, you agree to receive our marketing emails. Review our {privacy-policy-link-slot} for details on data handling.",
  privacyPolicyLinkText: "Privacy Policy",
  eclipse_Entity: "Legal Entity Name",
  eclipse_Partner_Opt_in_Processing__c : "I'd like to receive more information on this topic from the MasterControl Partner(s) affiliated with this event.",
  eclipse_NonBusinessEmail: "Email",
  
};

export default FormLabels;