<script lang="ts">
  // Turn off inheritance through attributes
  export default {
    inheritAttrs: false
  };
</script>

<script setup lang="ts">
  import type { FormWidgetData } from "../../../widgetDataTs.js";
  import { useFormServices } from "../../composables/use-form-services.js";
  import { computed, watch } from "vue";
  import roiForm from "../form-element/roi-form-base.vue";
  import { isNil } from "@mcwd/typescript-type-guards";
  import modalBody from "./roiForm/modal-body.vue";
  import { getCustomFormText } from "../../composables/use-custom-data-values.js";

  const props = defineProps<{
    formSettingsKey: "qx-roi" | "mx-roi";
    widgetData: FormWidgetData<"ContactForm">;
    customData?: {
      mainSolutionInterestDefault?: MainSolutionKey;
    } | undefined;
    isInModal?: boolean;
  }>();
  const isInModal = props.isInModal;
  const { FormState } = useFormServices({ callerLabel: "roiForm" });
  const showUnlockedView = computed(() => {
    return FormState.value === "RegularFormComplete";
  });
  const customFormHeading = computed(() => { return getCustomFormText(props.customData, 'formHeading'); });

  // Removes the utm-url class if form success
  const afterCompleteHandler = () => {
    $('.utm-url').removeClass('utm-url');
    $('.outer-wrpr').addClass('fs-roi');

  };

  watch(showUnlockedView, () => {
    if (showUnlockedView.value) {
      afterCompleteHandler();
    }
  });
</script>

<template>
  <!-- If in modal -->
  <template v-if="isInModal">
    <div id="eclipse-modal-content" class="eclipseBodyContent">
      <modalBody>
        <template #form>
          <roi-form :form-settings-key="formSettingsKey" :widget-data="widgetData" :custom-data="customData">
            <template v-if="!isNil(customFormHeading) && customFormHeading != ''" #heading>
              <h5 class="supporting-copy">
                {{ customFormHeading }}
              </h5>
            </template>
          </roi-form>
        </template>
      </modalBody>
    </div>
  </template>

  <!-- Not in modal -->
  <template v-else>
    <roiForm :form-settings-key="formSettingsKey" :widget-data="widgetData" :custom-data="customData" />
  </template>
</template>