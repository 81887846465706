const launchDocumentForm = (await import("../marketo/form-cta-modal-launcher.js")).launchDocumentForm;

// Hero Carousel
const heroCarousel = Vue.createApp({
  components: {
    Carousel: window.VueCarousel.Carousel,
    Slide: window.VueCarousel.Slide,
    Pagination: window.VueCarousel.Pagination,
    Navigation: window.VueCarousel.Navigation,
  },
  setup() {
    return {
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        touchDrag: true,
        mouseDrag: false,
        wrapAround: false,
        autoplay: 16000,
        transition: 500,
        pauseAutoplayOnHover: true
      },
      breakpoints: {
        320: {
          // itemsToShow: 1.25,
          // snapAlign: "start"
        }
      }
    };
  },
  data() {
    return {
      heroCarouselContent: heroCarouselContent,
      loading: true,
      hideControls: false,
    };
  },
  methods: {
    navigate(value) {
      const carousel = this.$refs.heroCarousel;
      if (value === 'prev') {
        carousel.prev();
      } else {
        carousel.next();
      }
    },
    async openForm(ctaButton: HTMLElement, docId) {
      console.log("ctaButton: ", ctaButton, "docId: ", docId);
      const instanceId = Math.random().toString();
      if (docId == null) {
        console.warn("DocId is null or undefined");
        return;
      }
      const docList = await this.getDocumentData(docId);
      this.addWidgetDataToPage(docList, instanceId);
      launchDocumentForm({
        $ctaButton: $(ctaButton), 
        $widgetEl: $(ctaButton), 
        widgetName: "Hero Carousel",
        variation: "",
        instanceId,
        ctaText: $(ctaButton).text(), 
        ctaType: "text",
        modalType: "Document"
      });
    },
    // This is the function that gets the JSON objects
    getDocumentData(legacyIdString) {
      // Get the query string doc ids
      const numberOfDocs = legacyIdString.split(",").length;
      const lang = window.AppState.GetCurrentLanguageCode().toLowerCase();
      // Get the Document List from the API
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'GET',
          url: `/customapi/ResourceWidgetData/GetDocumentData/?legacyIds=${legacyIdString}&lang=${lang}`,
          success: function (docList) {
            if (numberOfDocs !== docList.length) {
              console.log('Some of the documents were not valid');
            }
            resolve(docList);
          },
          error: function (jqXHR, textStatus, errorThrown) {
            console.log("JSON error");
            reject(errorThrown);
          }
        });
      });
    },
    addWidgetDataToPage(docList, instanceId) {
      console.log(docList);
      window.AppState.WidgetData.Add({
        widget: {
          name: "Document Request Form",
          variation: "",
          instanceId: instanceId
        },
        setup: {
          type: "ResourceForm",
          isInModal: true,
          form: {
            name: "Document Request Form",
            document: {
              isFilteredOrChosen: false,
              selectedListWillBeSingleDocument: true,
              sourceList: docList
            }
          },
          tracking: {
            usePageOfferId: false
          }
        }
      });
    }
  }
});

heroCarousel.mount('#hero-carousel');