import { isFunction, isString, type Nil } from "@mcwd/typescript-type-guards";

export type QueryParamParseFn<T> = (val: string) => (T | undefined);

export function getQueryParameter (key: string): string | Nil;
export function getQueryParameter<TDefault = string> (
  key: string, { defaultValue }: { defaultValue: TDefault }
): string | TDefault;
export function getQueryParameter<TParse> (
  key: string, { parse }: { parse: QueryParamParseFn<TParse> }
): TParse | string | Nil;
export function getQueryParameter<TParse, TDefault = string | TParse> (
  key: string,
  { defaultValue, parse }: { defaultValue: TDefault, parse: QueryParamParseFn<TParse> }
): TParse | string | TDefault;
export function getQueryParameter<TParse, TDefault = string | TParse>(
  key: string,
  { defaultValue, parse }: { defaultValue?: TDefault; parse?: QueryParamParseFn<TParse> } = {}
): TParse | string | TDefault | Nil
{
  type ThisReturnType = TParse | string | TDefault | Nil;
  const searchParams = new URLSearchParams(window.location.search);
  const value = searchParams.get(key)?.trim();

  let returnVal: ThisReturnType;
  if (isFunction(parse) && isString(value)) {
    try {
      returnVal = parse(value);
    } catch (e) {
      console.warn(e);
    }
  }
  else {
    returnVal = value as ThisReturnType;
  }
  return (returnVal ?? defaultValue);
}