<script lang="ts" setup>
  import type { DocumentResource } from '../../../../widgetDataTs.js';
  import imageWithFallback from '../../../../shared-components/image-with-fallback.vue';
  import { useFormAssetDownload } from '../../../composables/use-form-asset-download.js';
  import { TranslationNamespaceEnum } from "../../../locale/settings/translation-namespaces.js";
  import { useTranslation } from "../../../composables/use-translation.js";

  const { downloadResourceTracking } = useFormAssetDownload();
  defineProps<{ 
    document: DocumentResource;
  }>();

  const { translate } = useTranslation();
  // const HeadingTextMapKey = "document-Heading";
  const DownloadTextMapKey = "document-Download";

  const genericThumbUrl = "https://static.mastercontrol.com/assets/persist/images/document-thumbnail-generic.png";
</script>

<template>
  <div class="doc-content-wrapper container">
    <div class="thumbnail-wrapper">
      <imageWithFallback 
        alt="Cover Image of Document"
        :src="document.thumbnail"
        :fallback-src="genericThumbUrl"
        class="main-thumbnail"
        loading="lazy"
      />
    </div>
    <div class="d-flex flex-column download-now">
      <p class="content-label">
        {{ document.docType }}
      </p>
      <p class="doc-title">
        {{ document.title }}
      </p>
      <a aria-label="download document" 
        class="btn btn-primary" 
        :href="`/customapi/DocumentDownload/GetDocument/${document.legacyId}?lang=${document.language}`"
        @click="downloadResourceTracking({ asset: document, ctaType: 'button', ctaText: '' })"
      >
        <span>
          <svg height="19" viewbox="0 0 16 19" width="16" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.3145 8.70312C14.3965 8.62109 14.4375 8.53906 14.4375 8.375C14.4375 8.25195 14.3555 8.12891 14.2734 8.00586L13.4941 7.18555C13.3711 7.10352 13.248 7.0625 13.125 7.0625C12.9609 7.0625 12.8789 7.14453 12.7969 7.22656L8.94141 11.2051V0.992188C8.94141 0.869141 8.85938 0.746094 8.77734 0.664062C8.69531 0.582031 8.57227 0.5 8.44922 0.5H7.30078C7.13672 0.5 7.01367 0.582031 6.93164 0.664062C6.84961 0.746094 6.80859 0.869141 6.80859 0.992188V11.2051L2.95312 7.22656C2.87109 7.14453 2.74805 7.0625 2.625 7.0625C2.46094 7.0625 2.33789 7.10352 2.25586 7.18555L1.43555 8.00586C1.35352 8.12891 1.3125 8.25195 1.3125 8.375C1.3125 8.53906 1.35352 8.62109 1.47656 8.70312L7.50586 14.7734C7.58789 14.8965 7.71094 14.9375 7.875 14.9375C7.99805 14.9375 8.12109 14.8965 8.24414 14.7734L14.3145 8.70312ZM15.2578 16.7422C15.3809 16.7422 15.5039 16.8242 15.5859 16.9062C15.668 16.9883 15.75 17.1113 15.75 17.2344V18.3828C15.75 18.5469 15.668 18.6699 15.5859 18.752C15.5039 18.834 15.3809 18.875 15.2578 18.875H0.492188C0.328125 18.875 0.205078 18.834 0.123047 18.752C0.0410156 18.6699 0 18.5469 0 18.3828V17.2344C0 17.1113 0.0410156 16.9883 0.123047 16.9062C0.205078 16.8242 0.328125 16.7422 0.492188 16.7422H15.2578Z" fill="white"></path>
          </svg>
          {{ translate(DownloadTextMapKey, {ns: TranslationNamespaceEnum.postSubmitText}) }}
        </span>
      </a>
    </div>
  </div>
</template>