import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "ppc-contact-success"
}
const _hoisted_2 = { class: "form-complete" }
const _hoisted_3 = { class: "response" }
const _hoisted_4 = { class: "primary-heading" }
const _hoisted_5 = { class: "supporting-copy" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (!$setup.showUnlockedView)
    ? (_openBlock(), _createBlock($setup["contactMarketoForm"], {
        key: 0,
        "form-settings-key": $props.formSettingsKey,
        "widget-data": $props.widgetData,
        "custom-data": $props.customData
      }, null, 8 /* PROPS */, ["form-settings-key", "widget-data", "custom-data"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h2", _hoisted_4, _toDisplayString($setup.CustomPostMessageHeader ? $setup.CustomPostMessageHeader : "Thank you for your interest!"), 1 /* TEXT */),
            _createElementVNode("p", _hoisted_5, _toDisplayString($setup.CustomPostMessageBody ? $setup.CustomPostMessageHeader : "One of our team members will be in touch soon."), 1 /* TEXT */)
          ])
        ])
      ]))
}