import type { BucketAndSelectorConfig, PropConfig } from "./page-management-tool/pmt-interfaces";

// To download the CSV file
export class CsvDataServices {

  // eslint-disable-next-line complexity
  static downloadCSV<TParam extends object>(bucketConfig: BucketAndSelectorConfig[], tableConfig: PropConfig[], data: TParam[], requiredField:string[], filename: string) {
    // Build the title
    const today = new Date();
    const isoDate = today.toJSON().slice(0, 19);
    const fileTitle = filename + isoDate;
    const csvFileName = fileTitle + ".csv" || "export.csv";

    // Build the data based on config files
    //headers
    const csvTableHeaders = tableConfig.map(x => x.title).concat();
    const csvBucketHeaders = bucketConfig.map(x => x.bucketTitles.map(y => y.title)).flat();
    const csvHeaders = csvTableHeaders.concat(csvBucketHeaders).filter( x=> requiredField.includes(x));
    //actual page data that correspond to headers
    let csvContent = csvHeaders.join(',');
    let rowData: string[] = [];
    for (const item of data) {
      for (const el of tableConfig) {
        if(!csvHeaders.includes(el.title))continue;
        const val = el.getValue(item);
        if (val === null || val === undefined || val === "") {
          rowData.push("Not Set");
        }
        else if (typeof val === "string") {
          rowData.push(`"${val}"`);
        } else if (Array.isArray(val)) {
          const value = val.join(' | ');
          rowData.push(`"${value}"`);
        }
      }
      for (const el of bucketConfig.map(x => x.bucketTitles).flat()) {
        if(!csvHeaders.includes(el.title))continue;
        const val = el.getValue(item);
        if (val === null || val === undefined || val === "") {
          rowData.push("Not Set");
        }
        else if (typeof val === "string") {
          rowData.push(`"${val}"`);
        } else if (typeof val === "boolean") {
          const value = val ? "true" : "false";
          rowData.push(value);
        } else if (Array.isArray(val)){
          const value = val ? val.join(' | ') : " ";
          rowData.push(`"${value}"`);
        }
      }
      csvContent += "\n" + rowData.join(',');
      rowData = [];
    }
    //for special charaters to load in correctly
    const BOM = "\uFEFF";
    csvContent = BOM + csvContent;
    // Put the link on page and download the file
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", csvFileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
