<script lang="ts" setup>
  import { computed, watch } from 'vue';
  import { useFormServices } from "../../../composables/use-form-services.js";
  import { getUserInputData } from './qx-roi-calculations.js';
  import roiSuccess from "./roi-post-statement.vue";

  const roiData = getUserInputData();
  const { FormState } = useFormServices({ callerLabel: "roiForm" });
  const showUnlockedView = computed(() => {
    return FormState.value === "RegularFormComplete" || FormState.value === "BlindForm";
  });
  // Calculates the post form submit values
  const afterCompleteHandler = () => {
    $('#yeartwo-savings').text(roiData.gatedStats[0].value);
    $('#final-calculation .extra-savings').addClass('show');
    // display Thank You
    $('.roi-button').addClass('d-none');
    $('.thank-you').removeClass('d-none');
  };

  watch(showUnlockedView, () => {
    if (showUnlockedView.value) {
      afterCompleteHandler();
    }
  });
  
</script>

<template>
  <div class="eclipse-form-root mc-roi-form neutral" :class="{success: showUnlockedView}" data-uniq-form-id="">
    <div class="d-flex flex-column flex-lg-row justify-content-between">
      <div class="stats-wrpr dark">
        <!-- Convert this into the RoiFormBuilder build -->
        <section class="form-stats">
          <div class="user-inputs">
            <div v-for="(input, index) in roiData.userInputs" class="stat-row d-flex align-items-center justify-content-between">
              <p class="stat-name">
                {{ input.label }}
              </p>
              <p class="stat-value" :data-roi="index">
                {{ input.value }}
              </p>
            </div>
          </div>
          <div class="teaser-stats">
            <div v-for="input in roiData.teaserStats" class="stat-row d-flex align-items-center justify-content-between" :class="input.rowClass">
              <p class="stat-name">
                {{ input.label }}
              </p>
              <p class="stat-value" data-roi="5">
                {{ input.value }}
              </p>
            </div>
          </div>
        </section>
      </div>
      
      <!-- This is where the success loads -->
      <roi-success v-if="showUnlockedView" :gated-stats="roiData.gatedStats" />

      <!-- Eclipse Form Loads Here -->
      <div v-else class="prospect-form">
        <div id="eclipse-load">
          <slot name="form"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
