<script lang="ts">
  // Turn off inheritance through attributes
  export default {
    inheritAttrs: false
  };
</script>

<script setup lang="ts">
  import { type FormWidgetData } from "../../../widgetDataTs.js";
  import { watch, computed } from 'vue';
  import resourceForm from "../form-element/resource-form-base.vue";
  import ppcComplete from "./documentForm/ppc-complete.vue";
  import { useFormServices } from "../../composables/use-form-services.js";
  import { isNil } from "@mcwd/typescript-type-guards";
  import { getCustomFormText } from "../../composables/use-custom-data-values.js";

  const props = defineProps<{
    formSettingsKey: "ppc"
    widgetData: FormWidgetData<"ResourceForm">;
    customData?: Record<string, any> | undefined;
  }>();

  const customFormHeading = computed(() => { return getCustomFormText(props.customData, 'formHeading'); });

  const documents = computed(() => {
    return props.widgetData.setup.form.document.selected;
  });
  const { FormState, CurrentFormExperienceGatingState } = useFormServices({ callerLabel: "documentForm" });
  const showUnlockedView = computed(() => {
    return FormState.value === "RegularFormComplete" || FormState.value === "BlindForm" || CurrentFormExperienceGatingState.value === "NoGate";;
  });

  const afterCompleteHandler = () => {
    // Removes class from JS that looks if utm_campain exists
    $('.utm-url').removeClass('utm-url');
  };

  watch(showUnlockedView, () => {
    if (showUnlockedView.value === true) {
      afterCompleteHandler();
      $('.eclipse-parent').addClass('success');
    } else {
      $('.eclipse-parent').removeClass('success');
    }
  });
  const ungatedView = computed(()=>{
    return CurrentFormExperienceGatingState.value === 'NoGate';
  });
</script>

<template>
  <resourceForm v-if="!showUnlockedView" 
    :form-settings-key="formSettingsKey"
    :widget-data="widgetData"
    :documents="documents"
    :custom-data="customData"
  >
    <template v-if="!isNil(customFormHeading) && customFormHeading != ''" #heading>
      <h5 class="supporting-copy form-instructions-heading">
        {{ customFormHeading }}
      </h5>
    </template>
  </resourceForm>
  <template v-else-if="showUnlockedView">
    <ppcComplete :document="documents[0]" :custom-data="customData" :ungated-view="ungatedView" />
  </template>
</template>