<script lang="ts" setup>
import { useTranslation } from "../../../composables/use-translation.js";
import { TranslationNamespaceEnum } from "../../../locale/settings/translation-namespaces.js";

  const props = defineProps<{
    customData?: Record<string, any> | undefined;
  }>();
  const data = props.customData ?? { bulletList: '' } as { bulletList: string };
  const { translate } = useTranslation();
</script>

<template>
  <div v-if="data.bulletList !== ''" class="bullet-item-list">
    <h5>{{ translate("rc-list-header", {
      ns: TranslationNamespaceEnum.customTranslations
    }) }}</h5>
    <ul class="list" v-html="data.bulletList"></ul>
  </div>
</template>