import { isNil, type Nil } from "@mcwd/typescript-type-guards";
import type { App as VueApp } from "vue";
import type { AppFormType } from "./components/App-Form-Types.js";
import { BaseFormLogger as logger } from "./base-form-logger.js";
import { FormExperienceSettingsObject } from "./form-settings/form-experience/form-experience-settings.js";
import type { GetInitializeFormParametersBySettingsKey } from "./form-app-initializer.js";

const { initializeForm } = await import("./form-app-initializer.js");

/** Id for the dynamically inserted modal wrapper */
const dynamicModalWrapperId = "js-dynamic-modal-wrapper";
/** Selector for the dynamically inserted modal wrapper */
const dynamicWrapperSelector = `#${ dynamicModalWrapperId }`;

/** FormSettingsKey values for form-experience settings that have 'canUseModal' === true */
export type ModalFormSettingsKey = (
  {
    [ K in keyof FormExperienceSettingsObject ]: 
      [FormExperienceSettingsObject[ K ]["canUseModal"]] extends [true]
        ? K
        : never 
  }
)[keyof FormExperienceSettingsObject];

export const ModalFormSettingsKey = Object.keys(FormExperienceSettingsObject).filter(k => FormExperienceSettingsObject[ k ].canUseModal === true) as ModalFormSettingsKey[];

function removeDynamicWrapper() {
  const dynamicWrapper: HTMLElement | Nil = document.body.querySelector<HTMLElement>(dynamicWrapperSelector);
  if (!isNil(dynamicWrapper)) {
    logger.debug("remove dynamic wrapper");
    document.body.removeChild(dynamicWrapper);
  }
}

/** 
 * Dynamically create an div element as a container for a form modal.
 * 
 *  The div itself is just a wrapper and is positioned off of the screen so it is not visible 
 */
function createDynamicWrapper() {
  removeDynamicWrapper();
  let dynamicWrapper: HTMLElement | Nil = document.querySelector<HTMLElement>(dynamicWrapperSelector);
  if (isNil(dynamicWrapper)) {
    dynamicWrapper = document.createElement('div');
    dynamicWrapper.id = dynamicModalWrapperId;
    dynamicWrapper.style.position = "absolute";
    dynamicWrapper.style.left = "-10000px";
    dynamicWrapper.style.top = "auto";
    dynamicWrapper.style.width = "1px";
    dynamicWrapper.style.height = "1px";
    dynamicWrapper.style.overflow = "hidden";
    dynamicWrapper.style.width = "100px";
    dynamicWrapper.style.height = "100px";
    dynamicWrapper.classList.add('eclipse-top-wrpr');
    document.body.appendChild(dynamicWrapper);
    dynamicWrapper = document.querySelector<HTMLElement>(dynamicWrapperSelector) as HTMLElement;
  }
  return dynamicWrapper;
}

/** 
 * Receives the Vue App instance and component instance for the form that is currently displayed in the modal as parameters. 
 * This function sets up an event handler on the component instance to unmount the app and remove the dynamic modal wrapper when the modal is closed.
 */
function createWrapperCleanupHandlers(appInstance: VueApp<Element>, componentInstance: AppFormType) {
  const onEvent = componentInstance.onEvent;
  const offEvent = componentInstance.offEvent;
  const modalClosedHandler = () => {
    logger.debug("Calling modalClosedHandler");
    offEvent("modalClosed", modalClosedHandler);
    appInstance.unmount();
    removeDynamicWrapper();
  };
  logger.debug("Create onEvent");
  onEvent("modalClosed", modalClosedHandler);
  return { appInstance, componentInstance };
}

/** Export an object with two functions, `create` and `cleanup` that allow directly controlling creation and cleanup handler creation for the dynamic modal wrapper.
 *  This object is exposed solely for export from the "form-cta-modal-launcher" file where it is re-exported as dynamicModalWrapper for legacy code.
 *  We need to verify that the dynamicModalWrapper const exported from that file isn't used anywhere in legacy code or sitefinity database, then we can remove this and simply use the `launchModalForm` function instead.
 *  @deprecated -- Cannot remove until we verify that this isn't used anywhere in legacy code or script blocks on the website 
 */
export const DynamicModalWrapperManager = {
  create: createDynamicWrapper,
  cleanup: createWrapperCleanupHandlers
} as const;

type DistributedOmit<T, KeysToOmit extends PropertyKey> = 
  T extends any
    ? Omit<T, KeysToOmit>
    : never;
export async function launchModalForm<
  TFormSettingsKey extends Exclude<ModalFormSettingsKey, "digital-maturity-assessment">
> (
  options: DistributedOmit<GetInitializeFormParametersBySettingsKey<TFormSettingsKey>, "rootContainer">
)
{
  const rootContainer = createDynamicWrapper();
  logger.debug(`initializeForm<${ options?.formSettingsKey }>`);
  const fullArgs = { ...options, rootContainer } as GetInitializeFormParametersBySettingsKey<TFormSettingsKey>;
  const { appInstance, componentInstance } = await initializeForm<TFormSettingsKey>(fullArgs);
  createWrapperCleanupHandlers(appInstance, componentInstance);
  return { appInstance, componentInstance };
}