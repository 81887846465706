<script setup lang="ts">
  import { computed } from "vue";
  import MarketoInputSyncWrapper from "./marketo-input-sync-wrapper.vue";
  import type { MarketoUserInputMeta } from "../types/definitions/inputs/marketo-input-meta.js";
  import type { InputState } from "../composables/use-input-state.js";
  import { FormLabelKeysEnum } from "../locale/translation-keys/all-keys.js";
  import { TranslationNamespaceEnum } from "../locale/settings/translation-namespaces.js";
  import { refDefault, useVModel } from "@vueuse/core";
  import type { Nil } from "@mcwd/typescript-type-guards";
  import { useTranslation } from "../composables/use-translation.js";

  export interface PartnerOptInCheckBoxProps {
    formInputMeta: MarketoUserInputMeta<"Partner_Opt_in_Processing__c">;
    inputState: InputState;
    modelValue: boolean | Nil;
  }
  const props = defineProps<PartnerOptInCheckBoxProps>();

  const emit = defineEmits<(e: 'update:modelValue', modelValue: PartnerOptInCheckBoxProps["modelValue"]) => void>();

  const proxyModelValue = refDefault(useVModel(props, 'modelValue', emit), null);

  const parentWrapperClasses = computed(() => {
    return [{ error: props.inputState.showError, done: props.inputState.done, required: props.inputState.required }];
  });
  const { translate } = useTranslation();

</script>

<template>
  <MarketoInputSyncWrapper v-model="proxyModelValue" :marketo-input-name="formInputMeta.marketoName">
    <div :id="'parent_' + formInputMeta.marketoName" class="parent-field-wrpr " :data-type="formInputMeta.type" :class="parentWrapperClasses">
      <label class="eclipse-label" :for="formInputMeta.name">
        <p>
          {{ translate(FormLabelKeysEnum.eclipse_Partner_Opt_in_Processing__c, {ns: TranslationNamespaceEnum.formLabels}) }}
          <input :id="formInputMeta.name" v-model="proxyModelValue" :type="formInputMeta.type" :name="formInputMeta.name" :data-active-element-match-name="formInputMeta.marketoName">
          <span class="checkmark"></span>
        </p>
      </label>
    </div>
  </MarketoInputSyncWrapper>
</template>
