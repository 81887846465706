import { type ApplyMergeObjects } from "@mcwd/typescript-type-guards";
import type { ISiteLocale, ISiteLocaleWithExtendedCountryLocales } from "./ISiteLocale.js";
import { LocaleSettingsArray, SiteLocaleWithExtendedCountryLocaleArray } from "../locale-settings.js";
import type { SiteCountryCode } from "../Locales.js";
import type { ICountryLocale } from "./ICountryLocale.js";

type BuildExtendedLocaleMappedToSiteLocale<
  TSiteCountryCode extends SiteCountryCode,
  ExtendedCountryLocales extends readonly ICountryLocale[],
  Result extends readonly any[] = []
> = 
  ExtendedCountryLocales extends readonly [infer FirstItem extends ICountryLocale, ... infer Remaining extends readonly ICountryLocale[]]
    ? BuildExtendedLocaleMappedToSiteLocale<
      TSiteCountryCode,
      Remaining, [...Result, { [K in FirstItem["CountryLocale"]]: TSiteCountryCode }]
    >
    : Result;



type BuildSiteSettingsExtendedLocaleObjSplit<
  ARRAY extends readonly ISiteLocale[],
  Result extends readonly any[] = []
> =
  ARRAY extends readonly [
    infer FirstItem extends ISiteLocale,
    ...infer Remaining extends readonly ISiteLocale[]
  ]
  ? (
    [FirstItem] extends [{
      SiteCountryCode: infer TSiteCountryCode extends SiteCountryCode,
      ExtendedCountryLocales: infer ExtendedCountryLocales extends readonly ICountryLocale[]
    }]
    ? (
      BuildSiteSettingsExtendedLocaleObjSplit<
        Remaining,
        [
          ...Result,
          ...BuildExtendedLocaleMappedToSiteLocale<TSiteCountryCode, ExtendedCountryLocales>
        ]
      >
    )
    : BuildSiteSettingsExtendedLocaleObjSplit<Remaining, [...Result]>
  ) : Result;

export type BuildSiteSettingsExtendedLocaleObj<
  ARRAY extends readonly ISiteLocale[],
> = ApplyMergeObjects<BuildSiteSettingsExtendedLocaleObjSplit<ARRAY, []>>;

export function buildSiteLocaleExtendedLocaleSettingsObj(): BuildSiteSettingsExtendedLocaleObj<LocaleSettingsArray> {
  type ThisReturnType = BuildSiteSettingsExtendedLocaleObj<LocaleSettingsArray>;
  const localeLowerCaseMapFn = <TSiteLocale extends ISiteLocaleWithExtendedCountryLocales>(siteLocale: TSiteLocale) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
    return siteLocale.ExtendedCountryLocales.map(c => c.CountryLocale.toLowerCase());
  };
  const siteLocaleSettingsObj = (SiteLocaleWithExtendedCountryLocaleArray as ISiteLocaleWithExtendedCountryLocales[]).reduce(
    (result: Record<string, SiteCountryCode>, localeSettings: ISiteLocaleWithExtendedCountryLocales) => {
      const extendedLocalesObj = localeLowerCaseMapFn(localeSettings).reduce(
        (innerResult: Record<string, SiteCountryCode>, extLocaleKey: string) => {
          return Object.assign(innerResult, {
            [extLocaleKey]: localeSettings.SiteCountryCode
          });
        }, {} as Record<string, SiteCountryCode>
      );
      return { ...result, ...extendedLocalesObj };
    },
    {} as Record<string, SiteCountryCode>
  );
  Object.freeze(siteLocaleSettingsObj);

  return siteLocaleSettingsObj as ThisReturnType;
}
