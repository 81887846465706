import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "eclipse-content-wrpr" }
const _hoisted_2 = { class: "left-col resource dark" }
const _hoisted_3 = { class: "resources-header" }
const _hoisted_4 = { class: "requested before-complete" }
const _hoisted_5 = { class: "doc-content-wrapper container" }
const _hoisted_6 = { class: "prospect-form" }
const _hoisted_7 = { id: "eclipse-load" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["eclipse-form-root mc-document-form neutral", {success: $setup.showUnlockedView}]),
    "data-uniq-form-id": ""
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", _hoisted_4, _toDisplayString($setup.translate("general-modal-Heading", { ns: $setup.TranslationNamespaceEnum.postSubmitText })), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createCommentVNode(" if single doc or multi doc "),
          _renderSlot(_ctx.$slots, "multi-doc"),
          _renderSlot(_ctx.$slots, "single-doc")
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createCommentVNode(" Eclipse Form Loads Here "),
          _renderSlot(_ctx.$slots, "form")
        ]),
        _renderSlot(_ctx.$slots, "resource")
      ])
    ])
  ], 2 /* CLASS */))
}