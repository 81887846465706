<script setup lang="ts">
  import type { FormWidgetData } from '../../../widgetDataTs.js';
  import type { ContactFormSettingsKey } from "../../form-settings/form-experience/form-settings-keys.js";
  import ContactForm from "../form-element/contact-form-base.vue";
  import { useFormServices, useTranslation } from "../../composables/use-form-services.js";
  import { computed } from "vue";
  import { TranslationNamespaceEnum } from "../../locale/settings/translation-namespaces.js";
  import { ContactFormPostKeysEnum } from "../../locale/translation-keys/all-keys.js";
  defineProps<{
    formSettingsKey: ContactFormSettingsKey;
    widgetData: FormWidgetData<"ContactForm">;
    customData?: Record<string, any> | undefined;
  }>();
  const { FormState } = useFormServices({ callerLabel: "tradeshowGiveawayForm" });

  const showUnlockedView = computed(() => {
    return FormState.value === "RegularFormComplete";
  });
  const { translate } = useTranslation();
</script>

<template>
  <div class="eclipse-marketo-form-root js-formBuilderRoot mc-tradeshow-giveaway-form">
    <template v-if="!showUnlockedView">
      <ContactForm :form-settings-key="formSettingsKey" :widget-data="widgetData" :custom-data="customData" />
    </template>
    <template v-else>
      <!--thank you message-->
      <div class="form-completion-message after-complete">
        <h2 class="complete">{{ translate(ContactFormPostKeysEnum.tradeshowGiveawayMessage, {ns:TranslationNamespaceEnum.contactPostSubmit}) }}</h2>
      </div>
    </template>
  </div>
</template>