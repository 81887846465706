import { isNil, isString, type Nil } from "@mcwd/typescript-type-guards";
import { isFormWidgetData, type FormWidgetData, type FormSetupType } from "../widgetDataTs.js";
import { LinkDownloadType } from "../tracking/LinkDownloadType.js";
import { BaseFormLogger as logger } from "./base-form-logger.js";
import type { VideoFormSettingsKey } from "./form-settings/form-experience/form-settings-keys.js";
import type { ModalFormSettingsKey } from "./form-modal-launcher.js";

const { launchModalForm, DynamicModalWrapperManager } = await import("./form-modal-launcher.js");

interface CtaModalLauncherArgs {
  $ctaButton: JQuery<HTMLElement>;
  $widgetEl: JQuery<HTMLElement>;
  widgetName: string | Nil;
  variation: string | Nil;
  instanceId: string | Nil;
  ctaText: string | Nil;
  ctaType: LinkDownloadType | Nil;
  modalType: string | Nil;  
}

const DataAttrs = {
  widgetName: "data-widget-name",
  ctaText: "data-download-cta-text",
  setupName: "data-setup-name",
  videoGuidId: "data-video-guid-id",
  ctaType: "data-widget-cta-type",
  variation: "data-widget-variation",
  instanceId: "data-widget-instance-id",
  modalType: "data-modal-type",
} as const;

function getWidgetElFromCta($ctaButton: JQuery<HTMLElement>) {
  return $ctaButton.is(`*[${DataAttrs.widgetName}]`)
    ? $ctaButton
    : $ctaButton.closest(`*[${DataAttrs.widgetName}]`);
}

function getCtaText($ctaButton: JQuery<HTMLElement>) {
  let ctaText = (
    $ctaButton.is(`*[${DataAttrs.ctaText}]`)
      ? $ctaButton.attr(DataAttrs.ctaText)
      : null
  )?.trim();
  // If the current element has the "data-download-cta-text" attribute, use that text.
  // Otherwise look at the $ctaTextEl element for text.
  if (isNil(ctaText)) {
    // If this element is the cta text element, we can use it, otherwise we can look inside of the element to see there is a child element
    let $ctaTextEl = $ctaButton.is(".js-cta-text-element")
      ? $ctaButton
      : $ctaButton.find(".js-cta-text-element");
    // Use either the $ctaTextEl element, or default to the current element.
    if ($ctaTextEl.length === 0) {
      $ctaTextEl = $ctaButton;
    }
    // Get the text from the current element.
    ctaText = $ctaTextEl.text().trim();
  }
  return ctaText;
}

function getSetupNameFilter(argsObj: CtaModalLauncherArgs) {
  const { $ctaButton } = argsObj;
  const setupName = $ctaButton.attr(DataAttrs.setupName);
  if (isString(setupName) && setupName.trim().length > 0) {
    return { setupName };
  } else return {};
}

function getDocumentSelectorFilter(argsObj: CtaModalLauncherArgs) {
  const { widgetName, $widgetEl } = argsObj;

  if (widgetName === "DocSelectorBladeWidgetController") {
    // .attr("value") gets the values from both the checkbox inputs and the .docSelector-title elements
    const selected = ([] as JQuery<HTMLElement>[]).slice
      .call(
        $widgetEl.find(
          ".docSelector-list input:checked, .docSelector-list .docSelector-title[value]"
        )
      )
      .map((el) => $(el).attr("value"))
      .filter(isString);
    return { documentSelector: selected };
  }
  return {};
}

function getVideoSelectorFilter(argsObj: CtaModalLauncherArgs) {
  const { widgetName, $ctaButton } = argsObj;

  if (widgetName === "VideoCarouselBladeWidgetController") {
    const selectedVideoId = $ctaButton.attr("data-video-guid-id");
    return { videoSelector: selectedVideoId };
  }
  return {};
}

function getLauncherArgsObj($ctaButton: JQuery<HTMLElement>): CtaModalLauncherArgs {
  const $widgetEl = getWidgetElFromCta($ctaButton);
  const ctaText = getCtaText($ctaButton);
  const ctaType = $ctaButton.attr(DataAttrs.ctaType);
  const widgetName = $widgetEl.attr(DataAttrs.widgetName);
  const variation = $widgetEl.attr(DataAttrs.variation);
  const instanceId = $widgetEl.attr(DataAttrs.instanceId);
  const modalType = $widgetEl.attr(DataAttrs.modalType);
  
  return {
    $ctaButton,
    $widgetEl,
    widgetName,
    variation,
    instanceId,
    modalType,
    ctaText,
    ctaType: LinkDownloadType.includes(ctaType as LinkDownloadType) ? (ctaType as LinkDownloadType) : null,
  };
}

function setCtaValues(
  widgetData: FormWidgetData<"ResourceForm" | "VideoForm" | "GatedVideoForm">,
  argsObj: CtaModalLauncherArgs
) {
  const { ctaText, ctaType } = argsObj;
  widgetData.setup.tracking ??= { usePageOfferId: false };
  const { tracking } = widgetData.setup;
  tracking.ctaText = ctaText;
  tracking.ctaType = ctaType;
}

/** Export an object with two functions, `create` and `cleanup` that allow directly controlling creation and cleanup handler creation for the dynamic modal wrapper.
 *  This object is exposed only for legacy code.
 *  We need to verify that the dynamicModalWrapper const isn't used anywhere in legacy code or the sitefinity database, then we can remove this and simply use the `launchModalForm` function instead.
 *  @deprecated -- Cannot remove until we verify that this isn't used anywhere in legacy code or script blocks on the website 
 */
export const dynamicModalWrapper = DynamicModalWrapperManager;

export async function launchDocumentForm(argsObj: CtaModalLauncherArgs) {
  const { instanceId } = argsObj;

  if (isNil(instanceId)) {
    logger.warn("Property 'instanceId' cannot be Nil", argsObj);
    throw new Error("Property 'instanceId' cannot be Nil");
  }

  const setupNameFilter = getSetupNameFilter(argsObj);
  const docSelectorFilter = getDocumentSelectorFilter(argsObj);
  const modifiersObj = { ...setupNameFilter, ...docSelectorFilter } as {
    setupName?: string | undefined;
    documentSelector?: string[] | undefined;
  };

  const widgetData = window.AppState.WidgetData.Get(instanceId, modifiersObj);
  if (isFormWidgetData(widgetData, "ResourceForm", true)) {
    setCtaValues(widgetData, argsObj);

    logger.debug("initializeDocumentForm");
    const { appInstance, componentInstance } = await launchModalForm({ formSettingsKey: "document", widgetData, isInModal: true });
    return { appInstance, componentInstance };
  }
  throw new Error("The referenced widgetData is not of type 'ResourceForm'");
}

export async function launchVideoForm(argsObj: CtaModalLauncherArgs, formSettingsKey: VideoFormSettingsKey & ModalFormSettingsKey) {
  const { instanceId } = argsObj;

  if (isNil(instanceId)) {
    logger.warn("Property 'instanceId' cannot be Nil", argsObj);
    throw new Error("Property 'instanceId' cannot be Nil");
  }

  const setupNameFilter = getSetupNameFilter(argsObj);
  const videoSelectorFilter = getVideoSelectorFilter(argsObj);
  const modifiersObj = { ...setupNameFilter, ...videoSelectorFilter };

  const widgetData = window.AppState.WidgetData.Get(instanceId, modifiersObj);
  const setupType: FormSetupType = formSettingsKey === "video" ? "VideoForm" : "GatedVideoForm";
  if (isFormWidgetData(widgetData, setupType, true)) {
    setCtaValues(widgetData, argsObj);
    const { appInstance, componentInstance } = await launchModalForm({ formSettingsKey, widgetData, isInModal: true });
    return { appInstance, componentInstance };
  }
  throw new Error("The referenced widgetData is not of type 'VideoForm' or 'GatedVideoForm'");
}

declare global {
  interface Window {
    McForm?: {
      CtaModalLauncher?: {
        launchVideoForm: typeof launchVideoForm,
        launchDocumentForm: typeof launchDocumentForm
      }
    }
  }
}

// To be compatible with existing code that relies on globals
window.McForm ??= {};
window.McForm.CtaModalLauncher ??= {
  launchVideoForm,
  launchDocumentForm
};

export function initCtaModalLauncherListeners() {
  // Launch from .js-cta-form-launcher elements with data-modal-type === 'Document'
  $(document).on(
    "click",
    `.js-cta-modal-launcher[${DataAttrs.modalType}='Document']`,
    function (this: HTMLElement) {
      const launcherArgsObj = getLauncherArgsObj($(this));
      launchDocumentForm(launcherArgsObj).catch(logger.error);
    }
  );

  // Launch from .js-cta-form-launcher elements with data-modal-type === 'Video'
  $(document).on(
    "click",
    `.js-cta-modal-launcher[${DataAttrs.modalType}='Video']`,
    function (this: HTMLElement) {
      const launcherArgsObj = getLauncherArgsObj($(this));
      launchVideoForm(launcherArgsObj, 'video').catch(logger.error);
    }
  );
// Launch from .js-cta-form-launcher elements with data-modal-type === 'VideoGated'
  $(document).on(
    "click", 
    `.js-cta-modal-launcher[${DataAttrs.modalType}='VideoGated']`,
    function(this: HTMLElement){
      const launcherArgsObj = getLauncherArgsObj($(this));
      launchVideoForm(launcherArgsObj, "video-gated").catch(logger.error);
    }
  );
}
