<script setup lang="ts">
  import type { VideoFormSettingsKey } from "../../form-settings/form-experience/form-settings-keys.js";
  import { type FormWidgetData } from "../../../widgetDataTs.js";
  import { type Nil } from "@mcwd/typescript-type-guards";
  import { ref, computed, watch, onBeforeUpdate, onBeforeUnmount } from "vue";
  import { useBlindSubmit, useFormServices } from "../../composables/use-form-services.js";
  import videoContent from './resourceLibrary/video-content.vue';
  import noGateVideo from './resourceLibrary/no-gate-video.vue';
  import resourceForm from "../form-element/resource-form-base.vue";
  import { useFormLogger } from "../../composables/use-form-logger.js";
  import type { YouTubePlayer } from "../../../utils/YouTube/YouTubePlayer.js";

  const props = defineProps<{
    formSettingsKey: "video-gated" & VideoFormSettingsKey;
    widgetData: FormWidgetData<"GatedVideoForm">;
    customData?: Record<string, any> | undefined;
  }>();

  const { logger } = useFormLogger();

  logger.timeEnd(`${props.formSettingsKey}-experienceLoad`);
  logger.time(`${props.formSettingsKey}-experienceRender`);

  const data = computed<{ thumbnail?: string | Nil }>(() => {
    return props.customData ?? {};
  });

  // Removes the utm-url class if form success
  const afterCompleteHandler = () => {
    $('.utm-url').removeClass('utm-url');
  };

  const videoData = computed(() => {
    console.warn("Gating State: ", props.widgetData);
    return props.widgetData.setup.form.video.selected;
  });

  const { FormState, CurrentFormExperienceGatingState } = useFormServices({ callerLabel: "videoForm" });

  const showUnlockedView = computed(() => {
    return FormState.value === "RegularFormComplete" || FormState.value === "BlindForm" || CurrentFormExperienceGatingState.value === "NoGate";
  });

  // When form success or blind form
  const ungatedClass = computed(() => {
    if (CurrentFormExperienceGatingState.value === "NoGate") {
      return 'no-gate';
    }
    else if (FormState.value === "RegularFormComplete") {
      return 'ungated';
    }
    else if (FormState.value === "BlindForm") {
      return 'ungated blind';
    }
    else {
      return "";
    }
  });

  // Playing the video
  const videoPlaying = ref(false);
  const btnClick = ref(false);

  let ytPlayer:YouTubePlayer | null = new window.YouTubePlayer('yt-iframe', {
    width: '420',
    height: '315',
    videoId: videoData.value.youTubeId
  });
  ytPlayer.events.onStateChange.subscribe("playing-class-handler", (event: { data: number }) => {
    var state = event.data;
    if (state === 0) {
      // Remove the playing class
      videoPlaying.value = false;
    } else {
      // Add the player class if video is playing
      videoPlaying.value = true;
    }
  });

  let blindFormSubmitted = false;
  const { submitBlindFormIfEnabled } = useBlindSubmit();
  watch(btnClick, () => {
    if (btnClick.value && ytPlayer) {
      logger.debug('video btn clicked', btnClick.value);
      if (blindFormSubmitted === false) {
        blindFormSubmitted = true;
        submitBlindFormIfEnabled();
      }
      ytPlayer.playVideo();
      btnClick.value = false;
    }
  });

  // Removes the utm-url class if form success
  watch(() => showUnlockedView.value, (isUnlocked) => {
    if (isUnlocked) {
      afterCompleteHandler();
      if (FormState.value === "RegularFormComplete" && ytPlayer) {
        ytPlayer.playVideo();
      }
    }
  }, { immediate: true });

  let beforeUpdateLogged = false;
  onBeforeUpdate(() => {
    if (!beforeUpdateLogged) {
      beforeUpdateLogged = true;
      logger.timeEnd(`${props.formSettingsKey}-experienceRender`);
      logger.timeEnd(`${props.formSettingsKey}-from-init-to-first-render`);
      logger.timeEnd("initVideoFormExp");
    }
  });
  function cleanUpVideo() {
    if (ytPlayer !== null) {
      logger.debug("cleanUpVideo");
      (ytPlayer.player as YT.Player).destroy();
      ytPlayer = null;
    }
  }
  onBeforeUnmount(() => {
    cleanUpVideo();
  });
</script>

<template>
  <div class="resource-gated-video resource-video eclipse-version" :class="[ungatedClass, { playing: videoPlaying }]">
    <div class="container">
      <div class="row">
        <!-- The Main Video Content -->
        <videoContent :custom-data="data" :widget-data="widgetData">
          <template #no-gate-video>
            <noGateVideo @playclicked="$event => btnClick = $event" />
          </template>

          <template #resource-form>
            <div id="video-landing-page-form-wrapper" class="vid-form vid-space">
              <resourceForm :form-settings-key="formSettingsKey" :widget-data="widgetData" :disable-form-heading="true" :custom-data="customData" />
            </div>
          </template>
        </videoContent>
      </div>
    </div>
  </div>
</template>
