<script lang="ts">
  // Turn off inheritance through attributes
  export default {
    inheritAttrs: false
  };
</script>

<script setup lang="ts">
  import type { ContactFormSettingsKey } from "../../form-settings/form-experience/form-settings-keys.js";
  import type { FormWidgetData } from "../../../widgetDataTs.js";
  import { useFormServices } from "../../composables/use-form-services.js";
  import { computed, watch } from "vue";
  import ContactForm from "../form-element/contact-form-base.vue";
  import ContactPostSubmitStatement from "../contact-statement.vue";
  import {  isBoolean, isNil } from "@mcwd/typescript-type-guards";
  import { getCustomFormText } from "../../composables/use-custom-data-values.js";
  import type { MainSolutionKey } from "../../locale/translation-keys/main-solution-option-keys.ts";

  const props = defineProps<{
    formSettingsKey: ContactFormSettingsKey;
    widgetData: FormWidgetData<"ContactForm">;
    customData?: {
      mainSolutionInterestDefault?: MainSolutionKey;
    } | undefined;
    isInModal?: boolean;
  }>();
  const { FormState } = useFormServices({ callerLabel: "contactForm" });
  const showUnlockedView = computed(() => {
    return FormState.value === "RegularFormComplete";
  });
  const customFormHeading = computed(() => { return getCustomFormText(props.customData, 'formHeading'); });

  const ryh = computed(() => {
    if (props.customData && ("ryh" in props.customData)) {
      const contactType: unknown = props.customData.ryh;
      if (isBoolean(contactType)) {
        return contactType;
      }
    }
    return false;
  });

  // Removes the utm-url class if form success
  const afterCompleteHandler = () => {
    $('.utm-url').removeClass('utm-url');
  };

  watch(showUnlockedView, () => {
    if (showUnlockedView.value) {
      afterCompleteHandler();
    }
  });
</script>

<template>
  <div>
    <template v-if="!showUnlockedView">
      <ContactForm :form-settings-key="formSettingsKey" :widget-data="widgetData" :custom-data="customData">
        <template v-if="!isNil(customFormHeading) && customFormHeading != ''" #heading>
          <div class="form-instructions">
            <p v-if="ryh">
              <strong>{{ customFormHeading }}</strong>
            </p>
            <h3 class="form-header" v-else>{{ customFormHeading }}</h3>
          </div>
        </template>
      </ContactForm>
    </template>
    <template v-else>
      <ContactPostSubmitStatement :ryh="ryh" />
    </template>
  </div>
</template>