import type { FormValidationTranslationsObject } from "../../translation-keys/form-validation-keys.js";

const FormValidation: FormValidationTranslationsObject = {
  eclipse_FirstName_Error: "이름을 입력하세요",
  eclipse_LastName_Error: "성을 입력하세요",
  eclipse_Email_Error: "이메일 주소를 입력하세요",
  eclipse_Company_Error: "회사를 입력하세요",
  eclipse_Country_Error: "국가를 입력하세요",
  eclipse_State_Error: "Territory is Required",
  eclipse_Phone_Error: "전화번호를 입력하세요",
  eclipse_Tier__c_Error: "회사 규모를 입력하세요",
  eclipse_Industry_Error: "업종을 입력하세요",
  eclipse_Department__c_Error: "부서를 입력하세요",
  eclipse_Email_Opt_In__c_Error: "이 필드는 필수 항목입니다",
  eclipse_contactRequested_Error: "",     // NEED INFO FOR THIS
  eclipse_Webform_Comments_recent_value__c_Error: "", // NEED INFO FOR THIS
  eclipse_Latest_Solution_Interest__c_Error: "",   // NEED INFO FOR THIS
  eclipse_Entity_Error:"",
  eclipse_NonBusinessEmail_Error: "이메일 주소를 입력하세요",
  eclipse_Partner_Opt_in_Processing__c_Error: "",
};

export default FormValidation;
