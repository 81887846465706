<script lang="ts">
  // Turn off inheritance through attributes
  export default {
    inheritAttrs: false
  };
</script>

<script setup lang="ts">
  import { ref, watch, defineAsyncComponent, type Component, onBeforeUpdate, onMounted, computed } from 'vue';
  import { initFormServices, useFormServices } from "../composables/use-form-services.js";
  import { exhaustiveGuard, isBoolean, isObject, type Nil } from "@mcwd/typescript-type-guards";
  import genericModalVue from '../../shared-components/generic-modal.vue';
  import { useFormEvents } from '../composables/use-form-events.js';
  import { formSubmitBool } from "../composables/use-form-submit.js";
  import type { ExposedMethods } from "./App-Form-Types.js";
  import type { AnyFormSettingsKey } from '../form-settings/form-experience/form-settings-keys.js';
  import type { FormWidgetData } from "../../widgetDataTs.js";
  import type { SiteCountryCode } from '../../types-and-definitions/locale-defs/Locales.js';
  import { initFormLogger, useFormLogger } from '../composables/use-form-logger.js';
  import type { ILogger } from '../../utils/Logger/ILogger.js';
  import { BaseFormLogger } from '../base-form-logger.js';

  type GenericModalType = InstanceType<typeof genericModalVue>;
  const genericModal = genericModalVue as unknown as GenericModalType;

  interface AppFormComponentProps {
    formSettingsKey: AnyFormSettingsKey;
    widgetData: FormWidgetData;
    customData?: Record<string, any> | undefined;
    isInModal?: boolean | Nil;
    lang: SiteCountryCode;
  }

  const props = defineProps<AppFormComponentProps>();

  const GenericModalCloseType = computed(() => {
    if (props.customData && ("hardClose" in props.customData)) {
      const hardClose = props.customData.hardClose as boolean;
      if (isBoolean(hardClose)) {
        return hardClose;
      }
    }
    return false;
  });
  let logger: ILogger;
  try {
    initFormLogger(props.formSettingsKey);
    logger = useFormLogger({ callerLabel: "App-Form.vue" }).logger;
  }
  catch (err) {
    BaseFormLogger.trace("Error App-Form.vue while initializing logger", {
      formSettingsKey: props?.formSettingsKey,
    });
    throw err;
  }
  try {
    logger.debug("App-Form.vue => props: ", { ... props });
    initFormServices({
      formSettingsKey: props.formSettingsKey,
      widgetData: props.widgetData,
      isInModal: props.isInModal === true,
      lang: props.lang,
      customData: props.customData
    });
  }
  catch(err) {
    logger.trace("Error App-Form.vue while executing initFormServices", {
      formSettingsKey: props.formSettingsKey,
      widgetData: props.widgetData,
      isInModal: props.isInModal === true,
      lang: props.lang
    });
    throw err;
  }
  
  const { FormState, FormSettings, UniqInstanceId } = useFormServices({ callerLabel: "App-Form.vue" });

  // eslint-disable-next-line complexity
  const asyncFormExperience = defineAsyncComponent<Component<AppFormComponentProps>>(() => {
    logger.time(`${props.formSettingsKey}-experienceLoad`);
    switch (props.formSettingsKey) {
      case "case-study": 
        return import("./form-experience/case-study-form.vue");
      case "contact": 
        return import("./form-experience/contact-form.vue");
      case "demo-request": 
        return import("./form-experience/demo-request-form.vue");
      case "document-landing-page": 
        return import("./form-experience/document-landing-page-form.vue");
      case "document": 
        return import("./form-experience/document-form.vue");
      case "event-registration": 
        return import("./form-experience/event-registration-form.vue");
      case "partner": 
        return import("./form-experience/partner-form.vue");
      case "post-event-assets": 
        return import("./form-experience/post-event-assets-form.vue");
      case "ppc": 
        return import("./form-experience/ppc-form.vue");
      case "ppc-contact":
        return import("./form-experience/ppc-contact-form.vue");
      case "ppc-video":
        return import("./form-experience/ppc-video.vue");
      case "pricing":
        return import("./form-experience/pricing-form.vue");
      case "qx-roi":
        return import("./form-experience/roi-form.vue");
      case "mx-roi":
        return import("./form-experience/roi-form.vue");
      case "spark":
        return import("./form-experience/spark-form.vue");
      case "subprocessor-update-notification":
        return import("./form-experience/subprocessor-update-notification-form.vue");
      case "subscription":
        return import("./form-experience/subscription-form.vue");
      case "tradeshow-giveaway":
        return import("./form-experience/tradeshow-giveaway-form.vue");
      case "video-landing-page":
        return import("./form-experience/video-landing-page-form.vue");
      case "video-gated":
        return import("./form-experience/video-gated-in-modal.vue");
      case "video":
        return import("./form-experience/video-form.vue");
      case "digital-maturity-assessment": 
        return import("./form-experience/digital-maturity-assessment.vue");
      case "qx-demo-video":
        return import("./form-experience/qx-demo-video.vue");
      default:
        exhaustiveGuard(props.formSettingsKey);
    }
  });

  const eclipseModal = ref<GenericModalType | null>(null);

  (window as any).eclipseModal = eclipseModal;

  const { onEvent, offEvent, modalClosed } = useFormEvents({ callerLabel: "App-Form.vue"});

  watch([eclipseModal, () => props.isInModal], ([modal, isInModal]: [GenericModalType | null, boolean | Nil]) => {
    if (isInModal === true && isObject(modal)){
      modal.openModal();
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  watch(() => eclipseModal.value?.visible, (currentlyVisible?: boolean, previouslyVisible?: boolean) => {
    logger.debug("visibility changed", { currentlyVisible, previouslyVisible });
    if (currentlyVisible !== true && previouslyVisible === true) {
      // Stop modal from closing if form submit was clicked
      // logger.debug('formSubmitBool.value', formSubmitBool.value);
      if(formSubmitBool.value) {
        setTimeout(() => {
          
          // eslint-disable-next-line @typescript-eslint/unbound-method
          modalClosed.trigger({ eventName: "modalClosed" }).catch(logger.error);
        }, 1000);
      } else {
        // eslint-disable-next-line @typescript-eslint/unbound-method
        modalClosed.trigger({ eventName: "modalClosed" }).catch(logger.error);
      }
    }
  });

  defineExpose<ExposedMethods>({
    onEvent,
    offEvent
  });

  // const isLoading = computed(() => {
  //   return FormState.value === "BeforeLoad" || FormState.value === "Loading" || FormState.value === "Setup";
  // });

  // provide("isLoading", isLoading);
  let loggedMount = false;
  onMounted(() => {
    if (!loggedMount){
      loggedMount = true;
      logger.timeEnd(`${props.formSettingsKey}-mount`);
    }
  });

  let loggedBeforeUpdate = false;
  onBeforeUpdate(() => {
    if (!loggedBeforeUpdate) {
      loggedBeforeUpdate = true;
      logger.timeEnd(`${props.formSettingsKey}-beforeUpdate`);
    }
  });

  let loggedReady = false;
  watch(() => FormState.value, (state) => {
    if ((!loggedReady) && (state === "RegularForm" || state === "BlindForm")) {
      loggedReady = true;
      logger.timeEnd(`${props.formSettingsKey}-ready`);
    }
  });
  function customClose(){
    if(eclipseModal.value){
      eclipseModal.value.closeModal();
    }
  }

</script>

<template>
  <form v-if="FormState !== 'BeforeLoad'"
    :id="`mktoForm_${FormSettings.formId}`"
    class="d-none"
    :data-uniq-instance-id="UniqInstanceId"
  />

  <template v-if="isInModal === true">
    <genericModal id="eclipse-modal" ref="eclipseModal" class="eclipse-modal" data-testid="form-experience-root" :hard-close="GenericModalCloseType">
      <asyncFormExperience v-bind="props" @custom-close="customClose" />
    </genericModal>
  </template>
  <template v-else>
    <asyncFormExperience v-bind="props" data-testid="form-experience-root" />
  </template>
</template>
