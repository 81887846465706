<script setup lang="ts">
  import type { FormWidgetData } from "../../../widgetDataTs.js";
  import type { ContactFormSettingsKey } from '../../form-settings/form-experience/form-settings-keys.js';
  import RegistrationForm from '../form-element/event-registration-base-form.vue';
  import { computed } from "vue";
  import { useFormServices, useTranslation } from "../../composables/use-form-services.js";
  import { TranslationNamespaceEnum } from "../../locale/settings/translation-namespaces.js";
  import { ContactFormPostKeysEnum } from "../../locale/translation-keys/all-keys.js";

  defineProps<{
    formSettingsKey: ContactFormSettingsKey,
    widgetData : FormWidgetData<"ContactForm">,
    customData : Record<string, any>,
  }>();
  const { FormState } = useFormServices({ callerLabel: "event-registration" });

  const showUnlockedView = computed(() => {
    return FormState.value === "RegularFormComplete";
  });
  const { translate } = useTranslation();

</script>

<template>
  <div class="mcui-marketo-form-root js-formBuilderRoot mc-event-registration-form neutral" data-uniq-form-id="527511">
    <div class="mcui-marketo-modal popup-modal">
      <div class="left-col resource dark left-style d-flex flex-column justify-content-center" style="background-image: url('https://www.mastercontrol.com/images/default-source/prospect/events/event-registration-bg-default.jpg');">
        <!-- Left side code goes here -->
        <h2 class="form-headline">{{ customData.EventHeading }} </h2>
        <div class="d-flex flex-column flex-md-row form-event-datetime">
          <p> {{ customData.dateAndTime.date }} </p><span class="form-pipe">|</span>
          <p> {{ customData.dateAndTime.time }}</p><span class="form-pipe">|</span>
          <p> {{ customData.dateAndTime.duration }}</p>
        </div>
      </div>
      <template v-if="!showUnlockedView">
        <div class="prospect-form right-style">
          <RegistrationForm :form-settings-key="formSettingsKey" :widget-data="widgetData" :custom-data="customData" />
        </div>
      </template>
      <template v-else>
        <div class="form-complete d-flex flex-column justify-content-between">
          <div class="response">
            <h2 class="primary-heading">{{ translate(ContactFormPostKeysEnum.eventRegistrationMessage, {ns: TranslationNamespaceEnum.contactPostSubmit}) }}</h2>
            <p class="supporting-copy">
              {{ translate(ContactFormPostKeysEnum.eventRegistrationSupportCopy, {ns: TranslationNamespaceEnum.contactPostSubmit}) }}
            </p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>