<script setup lang="ts">
  import type { FormWidgetData } from "../../../widgetDataTs.js";
  import resourceForm from "../form-element/resource-form-base.vue";
  import type { MaturityFormKey } from "../../form-settings/form-experience/form-settings-keys.js";
  import { computed } from "vue";
  import { useFormServices, useBlindSubmit } from "../../composables/use-form-services.js";

  interface TierBucket{
    name: string,
    icon: string,
    iconGray: string,
    number: number,
    percent: number
  }
  const props= defineProps<{
    formSettingsKey: MaturityFormKey;
    widgetData:FormWidgetData<"MaturityForm">;
    customData?: Record<string, any> | undefined;
  }>();
  const tiers = props.customData?.fourTiers as TierBucket[];
  const emit =  defineEmits(['customClose']);
  const { FormState } = useFormServices({ callerLabel: "maturity-assessment-form" });

  const showUnlockedView = computed(() => {
    return FormState.value === "RegularFormComplete" || FormState.value === "BlindForm";
  });
  const showBlindFormComplete = computed(() => {
    return FormState.value === "BlindForm";
  });
  const { submitBlindFormIfEnabled } = useBlindSubmit();
  function sendEvent(){
    emit('customClose');
  }
</script>
<template> 
  <div class="modal-body">
    <div class="final-modal" :class="[showBlindFormComplete ? 'sans-gate' : 'gate']">
      <div class="modal-grid">
        <!-- The four types section -->
        <div v-if="!showUnlockedView || showBlindFormComplete" class="four-tier-wrpr">
          <div class="four-tier-icons">
            <div v-for="tier in tiers" :key="tier.name" class="four-icon" :class="[customData?.finalCategory === tier.name ? tier.name : '' ]">
              <img v-if="customData?.finalCategory === tier.name" :src="tier.icon" class="svg-icon" data-sf-ec-immutable="">
              <img v-else :src="tier.iconGray" class="svg-icon" data-sf-ec-immutable="">
              <p class="tier-name">
                {{ tier.name }}
              </p>
              <div class="circle"></div>
            </div>
          </div>
        </div>
      
        <template v-if="!showUnlockedView">
          <!-- Modal if questions are done and gated -->
          <div class="gate-section ">
            <h5 class="outro-header">You fall within the <span class="teal upper-case">{{ customData?.finalCategory }}</span> tier.</h5>
            <p class="tier-def">
              Your personalized report is waiting.
            </p>
            <p class="list-header">
              Access now to learn:
            </p>
            <ul class="listy-mclistface">
              <li>Where you rank in comparison to your peers.</li>
              <li>Your full results, including a plan for how to move to the next tier and the benefits you'll experience.</li>
              <li>Ways to overcome blockers keeping you from becoming more digitally mature.</li>
            </ul>
          </div>
          <!-- Form -->
          <resourceForm :form-settings-key="formSettingsKey" :widget-data="widgetData" :custom-data="customData" />
        </template>
        <template v-else-if="showBlindFormComplete">
          <div class="sans-gate-section">
            <div class="gap-wrpr">
              <h5 class="outro-header">You fall within the <span class="teal upper-case">digital</span> tier.</h5>
              <p class="tier-def">
                Thank you for completing the assessment. Your personalized report is ready.
              </p>
            </div>
            <button class="view-report btn btn-secondary" aria-label="Close" @click="submitBlindFormIfEnabled(); sendEvent()">
              View Report
            </button>
          </div>
        </template>
        <template v-else>
          <div class="success">
            <div class="gap-wrpr">
              <h5 class="outro-header">Thank You!</h5>
              <p class="tier-def">
                Your personalized report is ready.
              </p>
            </div>
            <button class="view-report btn btn-secondary" aria-label="Close" @click="sendEvent">
              View Report
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>