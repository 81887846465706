<script setup lang="ts">
  import { TranslationComponent as translation } from "i18next-vue";
  import { ContactFormPostKeysEnum } from "../locale/translation-keys/all-keys.js";
  import { TranslationNamespaceEnum } from "../locale/settings/translation-namespaces.js";
  import { useTranslation } from "../composables/use-translation.js";
  defineProps<{
    ryh?: boolean
  }>();
  const { translate } = useTranslation();
</script>

<template>
  <div class="form-completion-message after-complete">
    <img v-if="!ryh" class="email" src="https://static.mastercontrol.com/assets/persist/images/mcui-resources-sent.png" alt="email sent">
    
    <h2 class="secondary-heading color-teal text-center" v-if="!ryh">{{ translate(ContactFormPostKeysEnum.contactThankYou, {ns: TranslationNamespaceEnum.contactPostSubmit}) }}</h2>
    <h2 class="secondary-heading color-teal text-center" v-else>{{ translate(ContactFormPostKeysEnum.raiseYourHand, {ns: TranslationNamespaceEnum.contactPostSubmit}) }}</h2>

    <p class="text-center">
      <translation :translation="translate(ContactFormPostKeysEnum.contactMessage, { ns: TranslationNamespaceEnum.contactPostSubmit })" >
        <template #phone-number-slot>
          <a :href="translate(ContactFormPostKeysEnum.contactHrefValue, { ns: TranslationNamespaceEnum.contactPostSubmit })" data-st-wn="">
            {{ translate(ContactFormPostKeysEnum.contactPhoneNumber, { ns: TranslationNamespaceEnum.contactPostSubmit }) }}
          </a>
        </template>
      </translation>
    </p>
  </div>
</template>
