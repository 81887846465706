<script setup lang="ts">
  import eclipseForm from "../form-element/resource-form-base.vue";
  import type { DocumentFormSettingsKey } from "../../form-settings/form-experience/form-settings-keys.js";
  import { type FormWidgetData } from "../../../widgetDataTs.js";
  import {  computed, watch } from "vue";
  import { useBlindSubmit, useFormServices } from "../../composables/use-form-services.js";
  import { TranslationNamespaceEnum } from "../../locale/settings/translation-namespaces.js";
  import { useFormLogger } from "../../composables/use-form-logger.js";
  import { useTranslation } from "../../composables/use-translation.js";
  const props = defineProps<{
    formSettingsKey: DocumentFormSettingsKey;
    widgetData:  FormWidgetData< "PostEventForm"> ;
    customData?: Record<string, any> | undefined;
  }>();

  const { logger } = useFormLogger();

  const video = computed(()=>{
    if("video" in props.widgetData.setup.form){
      return props.widgetData.setup.form.video.selected;
    }
    return null;
  });
  const { FormState } = useFormServices({ callerLabel: "postEventForm" }); 
  const showUnlockedView = computed(() => {
    return FormState.value === "RegularFormComplete" || FormState.value === "BlindForm";
  });

  watch(showUnlockedView, () => {
    logger.warn("Show unlocked view", { showUnlockedView: showUnlockedView.value });
    if (showUnlockedView.value) {
      $('.page-wrpr.post-event-gated').addClass('ungated');
    } else {
      $('.page-wrpr.post-event-gated').removeClass('ungated');
    }
  });
  const { translate } = useTranslation();

  const { submitBlindFormIfEnabled } = useBlindSubmit();
</script>

<template>
  <div class="mcui-marketo-form-root mc-post-event-assets-form" :class="{success: showUnlockedView}">
    <eclipseForm v-if="!showUnlockedView" :form-settings-key="formSettingsKey" :widget-data="widgetData" :custom-data="customData" />
    <template v-else-if="showUnlockedView">
      <div class="form-completion-message after-complete">
        <h3 class="heading">{{ translate("post-event-assets-Heading", { ns: TranslationNamespaceEnum.postSubmitText }) }}</h3> <!--move this into post submit message-->
        <p>{{ translate("post-event-assets-Body", { ns: TranslationNamespaceEnum.postSubmitText }) }}</p>
        <a class="btn btn-primary js-cta" 
          href="#mcui-modal"
          data-modal-type="youtube"
          data-bs-toggle="modal" 
          :data-docid="video?.youTubeId"
          @click="submitBlindFormIfEnabled()"
        >
          <i class="fas fa-play"></i>{{ translate("post-event-assets-Download", { ns: TranslationNamespaceEnum.postSubmitText }) }}
        </a>
      </div>
    </template> 
  </div>
</template>